import { Machine, assign } from 'xstate';

const initialValues = {
    operation: 'OPERATION_NONE',
    last: 'LAST_NONE',
    extension: 'EXTENSION_NONE',
    special: 'SPECIAL_NONE',
}

export const calculate = (context, event, actionMeta) => {
    let value = JSON.parse(JSON.stringify(actionMeta.state?.value || initialValues))
    if (event?.payload?.identifier) {
        value[event.payload.identifier] = event.type
    }

    const {operation, last, extension, special} = value

    let formStatus = {}
    Object.keys(dimmerMachine.states).forEach(key => {
        formStatus[key] = {
            open: key === 'operation' ? true : false
        }
    })

    context = [{
        feature: "EC000025", //Dimmer
        filters: [
            {
                "Id": "EF000005", //Zusammenstellung
                "Value": "EV000078" //Basiselement
            },
            {
                "Id": "EF000003", //Montageart
                "Value": "EV000383" //Unterputz
            }
        ],
        additionalFilters: []
    }]

    if (operation === 'DREHBEDIENUNG') {
        context[0].filters.push({
            "Id": "EF000006", //Bedienungsart
            "Value": ["EV000092", "EV000251"] //EV000092 Drehknopf oder EV000251 Dreh-/Druckknopf
        })
    } else if (operation === 'TASTBEDIENUNG') {
        context[0].filters.push({
            "Id": "EF000006", //Bedienungsart
            "Value": ["EV008969", "EV000554"] //EV008969 Tipptaster oder EV000554 Drucktaster
        })
    } else if (operation === 'IR') {
        context[0].filters.push({
            "Id": "EF003908", //Verwendbar mit IR-Taste
            "Value": "true"
        })
    } else if (operation === 'DREHNEBENSTELLE') {
        context[0].additionalFilters.push('drehnebenstelle')
    }

    if (last === 'OHM') {
        context[0].filters.push( {
            "Id": "EF001508", //Lastart
            "Value": "EV006463" //ohmsche Last
        })
    } else if (last === 'INDUCTIVE') {
        context[0].filters.push( {
            "Id": "EF001508", //Lastart
            "Value": "EV006403" //induktive Last
        })
    } else if (last === 'KAPAZITIVE') {
        context[0].filters.push( {
            "Id": "EF001508", //Lastart
            "Value": "EV006425" //kapazitive Last
        })
    }  else if (last === 'EVG1_10V') {
        context[0].filters.push( {
            "Id": "EF001508", //Lastart
            "Value": "EV006788" //EVG 1-10 V
        })
    }  else if (last === 'UNIVERSAL') {
        context[0].filters.push( {
            "Id": "EF001508", //Lastart
            "Value": ["EV011953", "EV000028"] //EV011953 universal und LED Retrofit oder EV000028 universal
        })
    }

    if (extension === 'DREHNEBENSTELLE') {
        context[0].additionalFilters.push('mn_drehnebenstelle')
    } else if (extension === 'TASTER') {
        context[0].additionalFilters.push('mn_taster')
        context[0].filters.push({
            "Id": "EF003910", //Verwendbar mit Taste
            "Value": "true"
        })
    } else if (extension === 'WECHSEL_KREUZSCHALTER') {
        context[0].additionalFilters.push('mn_wechsel_kreuz')
    }

    if (special === 'WEITER_AUSGANG_GESCHALTET') {
        context[0].additionalFilters.push('ausgang_geschaltet')
    } else if (special === 'ZWEITER_AUSGANG_GEDIMMT') {
        context[0].additionalFilters.push('ausgang_gedimmt')
    }

    if (operation === 'OPERATION_NONE') {
        context.push({
            feature: ['EC001035', 'EC001588'], //EC001035 Bewegungsmelder Basiselement oder EC001588 rrrElektronischer Schalter
            filters: [{
                "Id": "EF000010", //Ausführung
                "Value": "EV004400" //Nebenstelle
            }],
            additionalFilters: []
        })
    }
    
    return {
        descriptions: context,
        formStatus
    }
}

export const dimmerMachine = Machine({
    id: 'dimmer',
    type: 'parallel',
    context: {},
    states: {
        operation: {
            initial: initialValues.operation,
            on: {
                OPERATION_NONE: 'operation.OPERATION_NONE',
                DREHBEDIENUNG: 'operation.DREHBEDIENUNG',
                TASTBEDIENUNG: 'operation.TASTBEDIENUNG',
                IR: 'operation.IR',
                DREHNEBENSTELLE: 'operation.DREHNEBENSTELLE',
            },
            states: {
                OPERATION_NONE: {
                    entry: ['calculate'],
                
                },
                DREHBEDIENUNG: {
                    entry: ['calculate'],
                
                },
                TASTBEDIENUNG: {
                    entry: ['calculate'],
                
                },
                IR: {
                    entry: ['calculate'],
                
                },
                DREHNEBENSTELLE: {
                    entry: ['calculate'],
                
                }
            }
        },
        last: {
            initial: initialValues.last,
            on: {
                LAST_NONE: 'last.LAST_NONE',
                INDUCTIVE: 'last.INDUCTIVE',
                KAPAZITVE: 'last.KAPAZITVE',
                OHM: 'last.OHM',
                EVG1_10V: 'last.EVG1_10V',
                UNIVERSAL: 'last.UNIVERSAL',
            },
            states: {
                LAST_NONE: {
                    entry: ['calculate'],
                
                },
                OHM: {
                    entry: ['calculate'],
                
                },
                INDUCTIVE: {
                    entry: ['calculate'],
                
                },
                KAPAZITVE: {
                    entry: ['calculate'],
                
                },
                EVG1_10V: {
                    entry: ['calculate'],
                
                },
                UNIVERSAL: {
                    entry: ['calculate'],
                
                }
            }
        },
        extension: {
            initial: initialValues.extension,
            on: {
                EXTENSION_NONE: 'extension.EXTENSION_NONE',
                DREHNEBENSTELLE: 'extension.DREHNEBENSTELLE',
                TASTER: 'extension.TASTER',
                WECHSEL_KREUZSCHALTER: 'extension.WECHSEL_KREUZSCHALTER'
            },
            states: {
                EXTENSION_NONE: {
                    entry: ['calculate'],
                
                },
                DREHNEBENSTELLE: {
                    entry: ['calculate'],
                
                },
                TASTER: {
                    entry: ['calculate'],
                
                },
                WECHSEL_KREUZSCHALTER: {
                    entry: ['calculate'],
                
                }
            }
        },
        special: {
            initial: initialValues.special,
            on: {
                SPECIAL_NONE: 'special.SPECIAL_NONE',
                ZWEITER_AUSGANG_GESCHALTET: 'special.ZWEITER_AUSGANG_GESCHALTET',
                ZWEITER_AUSGANG_GEDIMMT: 'special.ZWEITER_AUSGANG_GEDIMMT',
            },
            states: {
                SPECIAL_NONE: {
                    entry: ['calculate'],
                
                },
                ZWEITER_AUSGANG_GESCHALTET: {
                    entry: ['calculate'],
                
                },
                ZWEITER_AUSGANG_GEDIMMT: {
                    entry: ['calculate'],
                
                }
            }
        }
    }
}, {
    actions: {
      calculate:  assign(calculate),
    }
});

export const possibleDimmerClasses = {
    //EC000025 Dimmer
    //EC001035 Bewegungsmelder Basiselement
    //EC001588 Elektronischer Schalter
    classes: ['EC000025', 'EC001035', 'EC001588'],
    types: {
        'DREHBEDIENUNG': dimmerMachine.transition(dimmerMachine.initialState, { type: 'DREHBEDIENUNG', payload: { identifier: 'operation' } })?.context?.descriptions,
        'TASTBEDIENUNG': dimmerMachine.transition(dimmerMachine.initialState, { type: 'TASTBEDIENUNG', payload: { identifier: 'operation'} })?.context?.descriptions,
        'DREHNEBENSTELLE': dimmerMachine.transition(dimmerMachine.initialState, { type: 'DREHNEBENSTELLE', payload: { identifier: 'operation'} })?.context?.descriptions
    }
}

export default dimmerMachine