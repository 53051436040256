import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import reducer from '../rootReducer';
import * as Sentry from '@sentry/react'; 
import createSentryMiddleware from 'redux-sentry-middleware';
import updateProject from '../middleware/updateProject';

// #region createStore : enhancer
const enhancer = compose(applyMiddleware(
  thunkMiddleware,
  createSentryMiddleware(Sentry, {
    // Optionally pass some options here.
  }),
  updateProject,
));
// #endregion

// #region persisted reducer
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  migrate: (state) => {
    return Promise.resolve(state)
  },
  blacklist: ['availableDevices', 'favorites']
};

const persistedReducer = persistReducer(persistConfig, reducer);
// #endregion

// #region store initialization
export default function configureStore(initialState) {
  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
}
// #endregion
