import { calculatePossibleDevice } from './calculatePossibleDevice'

export const calculateTouchProtectionForTemplates = (
    article,
    deviceList,
    articleColor,
    features,
    touchProtection,
    additionalData
) => {
    let filters = []
    const description = {
        feature: 'EC000125', //Steckdose
        manufacturerTypeDescr: '-' + articleColor,
    }
    features.forEach(feature => {
        if (feature.Fname === 'EF000389') { //Mit erhöhtem Berührungsschutz
            filters.push({
                Id: feature.Fname,
                Value: `${touchProtection}`,
            })
        } else {
            filters.push({
                Id: feature.Fname,
                Value: feature.Fvalue,
            })
        }
    })

    const possibleDevices = calculatePossibleDevice(deviceList, [{ ...description, filters }], additionalData)

    const possibleDevice = possibleDevices.find(el => el.supplierPid === article.supplierPid)
     || possibleDevices[0]
    
    if (possibleDevice) {
        article.availableDeviceId = possibleDevice.manufacturerTypeDescr
        article.supplierPid = possibleDevice.supplierPid
    } else {
        filters = [
            {
                Id: 'EF000389', //Mit erhöhtem Berührungsschutz
                Value: `${touchProtection}`,
            }
        ]
        const possibleDevices2 = calculatePossibleDevice(deviceList, [{ ...description, filters }], additionalData)

        const possibleDevice2 = possibleDevices2.find(el => el.supplierPid === article.supplierPid)
        
        if (possibleDevice2) {
            article.availableDeviceId = possibleDevice2.manufacturerTypeDescr
            article.supplierPid = possibleDevice2.supplierPid
        } else {
            let bestPossibleDevice = {}
            if (possibleDevices.length > 0) {
                let bestMatches = 0
                possibleDevices2.forEach(pDev => {
                    let matches = 0
                    const pFeatures = JSON.parse(pDev.features)
                    pFeatures[0].Feature.forEach(feature => {
                        if (
                            feature.Fname !== 'EF005474' && //Schutzart (IP)
                            feature.Fname !== 'EF004293' && //Schlagfestigkeit
                            feature.Fname !== 'EF002442' && //Befestigungsart
                            feature.Fname !== 'EF000008' && //Breite
                            feature.Fname !== 'EF000040' && //Höhe
                            feature.Fname !== 'EF000049' && //Tiefe
                            feature.Fname !== 'EF000846' && //Einbaubreite
                            feature.Fname !== 'EF000332' && //Einbauhöhe
                            feature.Fname !== 'EF009277'    //Durchmesser Bohrung (Öffnung)
                        ) {
                            const matchedFeature = features.find(
                                feat => feat.Fname === feature.Fname && feat.Fvalue === feature.Fvalue
                            )
                            if (matchedFeature) {
                                matches++
                            }
                        }
                    })
                    if (matches > bestMatches) {
                        bestPossibleDevice = pDev
                        bestMatches = matches
                    }
                })
            }
            if (bestPossibleDevice?.supplierPid) {
                article.availableDeviceId = bestPossibleDevice.manufacturerTypeDescr
                article.supplierPid = bestPossibleDevice.supplierPid
            }
        }
    }

    return article
}
