
export const checkDeviceForClass = (devices, descriptions) => {
    return devices.filter((device, index) => {
            return checkDevicesForFeatures({features: device.getFeatures()}, descriptions)
        
    })
}

export const checkDeviceForColor = (device, descriptions) => {
    if (descriptions[0].manufacturerTypeDescr) {
        if (Array.isArray(descriptions[0].manufacturerTypeDescr)) {
            let chk = false
            descriptions[0].manufacturerTypeDescr.forEach(desc => {
                if (device.manufacturerTypeDescr.includes(desc)) {
                    if ((descriptions[0].manufacturerTypeDescr === '-182' && device.manufacturerTypeDescr.includes('-182K'))
                        || (descriptions[0].manufacturerTypeDescr === '-212' && device.manufacturerTypeDescr.includes('-212K'))
                        || (descriptions[0].manufacturerTypeDescr === '-214' && device.manufacturerTypeDescr.includes('-214K'))
                    ) {
                        chk = false
                    } else if (descriptions[0].manufacturerTypeDescr === '-82' && device.manufacturerTypeDescr.includes('12-82')) {
                        chk = false
                    } else {
                        chk = true
                    }
                }
            })
            if (!chk) {
                return false
            }
        } else {
            if (!device.manufacturerTypeDescr.includes(descriptions[0].manufacturerTypeDescr)) {
                return false
            } else {
                if ((descriptions[0].manufacturerTypeDescr === '-182' && device.manufacturerTypeDescr.includes('-182K'))
                    || (descriptions[0].manufacturerTypeDescr === '-212' && device.manufacturerTypeDescr.includes('-212K'))
                    || (descriptions[0].manufacturerTypeDescr === '-214' && device.manufacturerTypeDescr.includes('-214K'))
                ) {
                    return false
                }
                if (descriptions[0].manufacturerTypeDescr === '-82' && device.manufacturerTypeDescr.includes('12-82')) {
                    return false
                }
            }
        }
    }
    return true
} 

export const checkDevicesForFeatures = (device, descriptions) => {
    let chk = false

    descriptions.forEach(description => {
        if (Array.isArray(description.feature)) {
            description.feature.forEach(feat => {
                if (device.features.includes(feat)) {
                    chk = true
                }
            })
        } else {
            chk = device.features.includes(description.feature)
        }
    })
    return chk
}

export const checkDevicesForFeatures2 = (device, descriptions) => {
    let chk = false

    descriptions.forEach((description, index) => {
        let chkDevice = false
        if (Array.isArray(description.feature)) {
            description.feature.forEach(feat => {
                if (device.features.includes(feat)) {
                    chkDevice = true
                }
            })
        } else {
            chkDevice = device.features.includes(description.feature)
        }
        
        if (chkDevice) {
            let chk1 = true
            description.filters.forEach(filter => {
                if (Array.isArray(filter.Value)) {
                    let arrayChk = false
                    filter.Value.forEach(value => {
                        if (device.features.includes(
                            `"Fname": "${filter.Id}", "Fvalue": "${value}"`
                        )) {
                            arrayChk = true
                        }
                    })
                    if (!arrayChk) {
                        chk1 = false
                    }
                } else {
                    if (!device.features.includes(
                        `"Fname": "${filter.Id}", "Fvalue": "${filter.Value}"`
                    )) {
                        chk1 = false
                    }
                }
            })
            if (chk1) {
                chk = true
            }
        }
    })
    return chk
}

export const checkForAdditionalFilters = (device, additionalData, descriptions, serie) => {
    let data = additionalData.find(el => {
        return el.supplier_pid === device.supplierPid
    })

    let chk = true
    if (data) {
        if (data.exclude && (data.exclude.all || serie && data.exclude[JSON.stringify(serie)])) {
            chk = false
        }
    }
    
    if (chk === true && descriptions[0].additionalFilters?.length > 0) {
        if (data) {
            descriptions[0].additionalFilters.forEach(key => {
                if (data[key] === "0" || data[key] === false || data[key] === undefined) {
                    chk = false
                }  
            })
        } else {
            chk = false
        }

    }
    return chk
}

export const calculatePossibleDevice = (deviceList, descriptions, additionalData = [], serie = null) => {
    let devices = deviceList.map(dev => {
        return {
            supplierPid: dev.getSupplierpid(),
            manufacturerTypeDescr: dev.getManufacturertypedescr(),
            features: dev.getFeatures(),
        }
    })


    if (descriptions && descriptions.length > 0) {
        devices = devices.filter(device => {
            let chk = checkDeviceForColor(device, descriptions)

            if (!chk) {
                return false
            }

            return checkDevicesForFeatures(device, descriptions)
        })

        devices = devices.filter(device => {
            return checkDevicesForFeatures2(device, descriptions)
        })

        if (additionalData && Array.isArray(additionalData) && additionalData.length > 0) {
            devices = devices.filter(device => {
                return checkForAdditionalFilters(device, additionalData, descriptions, serie)
            })
        }

    }
    return devices
}