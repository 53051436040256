import { Machine, assign } from 'xstate'

const initialValues = {
    type: 'BEWEGUNGSMELDER',
    lence: 'LENCE_NONE',
    connection: 'CONNECTION_NONE',
    channel: 'CHANNEL_NONE',
    extension: 'EXTENSION_NONE',
    voltage: 'VOLTAGE_NONE',
    specials: 'SPECIALS_NONE',
}

export const calculate = (context, event, actionMeta) => {
    let value = JSON.parse(JSON.stringify(actionMeta.state?.value || initialValues))
    if (event?.payload?.identifier) {
        value[event.payload.identifier] = event.type
    }
    const { type } = value

    let formStatus = {}
    Object.keys(motionMachine.states).forEach(key => {
        formStatus[key] = {
            open: key === 'type' ? true : false,
        }
    })

    context = []

    if (type === 'BEWEGUNGSMELDER') {
        context.push({
            feature: 'EC000138', //Bewegungsmelder-Sensor
            filters: [
                {
                    Id: 'EF000010', //Ausführung
                    Value: 'EV004048', //Bewegungsmelder
                },
                {
                    Id: 'EF000003', //Montageart
                    Value: 'EV000383', //Unterputz
                }
            ],
            additionalFilters: ['motionDetection'],
        })
    } else if (type === 'KOMFORTSCHALTER') {
        context.push({
            feature: 'EC000138', //Bewegungsmelder-Sensor
            filters: [
                {
                    Id: 'EF000010', //Ausführung
                    Value: 'EV004048', //Bewegungsmelder
                },
                {
                    Id: 'EF000003', //Montageart
                    Value: 'EV000383', //Unterputz
                }
            ],
            additionalFilters: ['komfortschalter'],
        })
    }

    return {
        descriptions: context,
        formStatus,
    }
}

export const motionMachine = Machine(
    {
        id: 'motion',
        type: 'parallel',
        context: {},
        states: {
            type: {
                initial: initialValues.type,
                on: {
                    BEWEGUNGSMELDER: 'type.BEWEGUNGSMELDER',
                    KOMFORTSCHALTER: 'type.KOMFORTSCHALTER',
                },
                states: {
                    BEWEGUNGSMELDER: {
                        entry: ['calculate'],
                    },
                    KOMFORTSCHALTER: {
                        entry: ['calculate'],
                    },
                },
            },
        },
    },
    {
        actions: {
            calculate: assign(calculate),
        },
    }
)

export const possibleMotionClasses = {
    //EC000138 Bewegungsmelder-Sensor
    classes: ['EC000138'],
    types: {
        'BEWEGUNGSMELDER': motionMachine.initialState?.context?.descriptions,
        'KOMFORTSCHALTER': motionMachine.transition(motionMachine.initialState, { type: 'KOMFORTSCHALTER', payload: { identifier: 'type'}})?.context?.descriptions,
    }
}

export default motionMachine
