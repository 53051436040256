import axios from 'axios'
import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'
import { queryClient } from '../../vendor/queryClient'
import { FETCH_FAVORITES } from '../api'
import { getCplateMapping, getDeviceList } from '../availableDevices/duck'
import { getDesignInformationFromProject } from '../projects/duck'
import { pick } from 'ramda'


export const initialState = {
    favorites: [],
}

const favoritesSlice = createSlice({
    name: 'favorites',
    initialState,
    reducers: {
        setFavorites(state, action) {
            state.favorites = action.payload.favorites
        }
    },
})

export const {
    setFavorites,
} = favoritesSlice.actions
export default favoritesSlice.reducer

export const fetchAddFavorite = (data) => {
    return axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL + '/api/favorites',
        data,
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function (favorites) {
            queryClient.invalidateQueries(FETCH_FAVORITES)
        })
}

export const fetchRemoveFavorite = (id) => {
    return axios({
        method: 'DELETE',
        url: process.env.REACT_APP_API_URL + '/api/favorites/' + id,
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function (favorites) {
            queryClient.invalidateQueries(FETCH_FAVORITES)
        })
        
}

export const getFavorites = state => state.favorites.favorites

export const getFavoriteFromCombination = (state, combination) => {
    return createDraftSafeSelector(
        [getDeviceList, getCplateMapping, getDesignInformationFromProject],
        (deviceList, cplateMapping, designInfomationen) => {
            const articleColor = cplateMapping.find(el => {
                return el.serie === designInfomationen.frameRangeId
                    && el.colorId === designInfomationen?.switchColorId
            })?.articleColor

            let tmpFavorite = {
                ...pick(['type', 'rootEntityId', 'entities'], combination),
                type: 'operationPoint',
            }

            if (tmpFavorite?.entities) {
                Object.keys(tmpFavorite.entities).forEach(key => {
                    let entity = tmpFavorite.entities[key]
                    if (entity.availableDeviceId) {
                        const originDevice = deviceList.find(el => el.getSupplierpid() === entity.availableDeviceId)
                        if (originDevice) {
                            tmpFavorite = {
                                ...tmpFavorite,
                                entities: {
                                    ...tmpFavorite.entities,
                                    [key]: {
                                        ...tmpFavorite.entities[key],
                                        availableDeviceId: originDevice.getManufacturertypedescr().replace(`-${articleColor}`, '-XX')
                                    }
                                }
                            }
                        }
                    }
                })
            }
            return tmpFavorite
        }
    )(state)
}