import { Machine, assign } from 'xstate';

const initialValues = {
    type: 'TYPE_NONE',
    label: 'LABEL_NONE',
    color: 'COLOR_NONE',
    special: 'SPECIAL_NONE',
}

export const calculate = (context, event, actionMeta) => {
    let value = JSON.parse(JSON.stringify(actionMeta.state?.value || initialValues))
    if (event?.payload?.identifier) {
        value[event.payload.identifier] = event.type
    }
    const {type, label, color, special} = value

    let formStatus = {}
    Object.keys(socketMachine.states).forEach(key => {
        formStatus[key] = {
            open: key === 'type' ? true : false
        }
    })

    context = [{
        feature: "EC000125", //Steckdose
        filters: [
        ],
    }]

    if (type === 'ERHOEHTER_BERUEHRUNGSSCHUTZ') {
        context[0].filters.push({
            "Id": "EF000389", //Mit erhöhtem Berührungsschutz
            "Value": "true"
        })
    } else if (type ==='SCHUKO') {
        context[0].filters.push({
            "Id": "EF000010", //Ausführung
            "Value": "EV002727" //SCHUKO
        })
    } else if (type ==='KLAPPDECKEL') {
        context[0].filters.push({
            "Id": "EF000117", //Mit Klappdeckel
            "Value": "true"
        })
    } else if (type ==='UEBERSPANNUNGSSCHUTZ') {
        context[0].filters.push({
            "Id": "EF001332", //Überspannungsschutz
            "Value": "true"
        })
    }

    if (label === 'BESCHRIFTUNGSFELD') {
        context[0].filters.push({
            "Id": "EF001035", //Textfeld/Beschriftungsfläche
            "Value": "true"
        })
    } else if (label === 'EDV') {
        context[0].filters.push({
            "Id": "EF003823", //Aufdruck/Kennzeichnung
            "Value": "EV006379" //EDV
        })
    }

    if (color === 'GRUEN') {
        context[0].filters.push({
            "Id": "EF000007", //Farbe
            "Value": "EV000235" //grün
        })
    } else if (color === 'ORANGE') {
        context[0].filters.push({
            "Id": "EF000007", //Farbe
            "Value": "EV000236" //orange
        })
    }

    if (special === 'KONTROLLLICHT') {
        context[0].filters.push({
            "Id": "EF008861", //Mit Funktionsbeleuchtung
            "Value": "true"
        })
    }

    return {
        descriptions: context,
        formStatus,
    }
}

export const socketMachine = Machine({
    id: 'socket',
    type: 'parallel',
    context: {},
    states: {
        type: {
            initial: initialValues.type,
            on: {
                TYPE_NONE: 'type.TYPE_NONE',
                ERHOEHTER_BERUEHRUNGSSCHUTZ: 'type.ERHOEHTER_BERUEHRUNGSSCHUTZ',
                KLAPPDECKEL: 'type.KLAPPDECKEL',
                UEBERSPANNUNGSSCHUTZ: 'type.UEBERSPANNUNGSSCHUTZ'
            },
            states: {
                TYPE_NONE: {
                    entry: ['calculate'],
                },
                ERHOEHTER_BERUEHRUNGSSCHUTZ: {
                    entry: ['calculate'],
                },
                KLAPPDECKEL: {
                    entry: ['calculate'],
                },
                UEBERSPANNUNGSSCHUTZ: {
                    entry: ['calculate'],
                }
            }
        },
        label: {
            initial: initialValues.label,
            on: {
                LABEL_NONE: 'label.LABEL_NONE',
                BESCHRIFTUNGSFELD: 'label.BESCHRIFTUNGSFELD',
                EDV: 'label.EDV'
            },
            states: {
                LABEL_NONE: {
                    entry: ['calculate'],
                },
                BESCHRIFTUNGSFELD: {
                    entry: ['calculate'],
                },
                EDV: {
                    entry: ['calculate'],
                }
            }
        },
        color: {
            initial: initialValues.color,
            on: {
                COLOR_NONE: 'color.COLOR_NONE',
                GRUEN: 'color.GRUEN',
                ORANGE: 'color.ORANGE'
            },
            states: {
                COLOR_NONE: {
                    entry: ['calculate'],
                },
                GRUEN: {
                    entry: ['calculate'],
                },
                ORANGE: {
                    entry: ['calculate'],
                }
            }
        },
        special: {
            initial: initialValues.special,
            on: {
                SPECIAL_NONE: 'special.SPECIAL_NONE',
                KONTROLLLICHT: 'special.KONTROLLLICHT'
            },
            states: {
                SPECIAL_NONE: {
                    entry: ['calculate'],
                },
                KONTROLLLICHT: {
                    entry: ['calculate'],
                }
            }
        }
    }
}, {
    actions: {
        calculate:  assign(calculate)
    }
});

export const possibleSocketClasses = {
    //EC000125 Steckdose
    classes: ['EC000125'],
    types: {
        'ERHOEHTER_BERUEHRUNGSSCHUTZ': socketMachine.transition(socketMachine.initialState, { type: 'ERHOEHTER_BERUEHRUNGSSCHUTZ', payload: { identifier: 'type'}})?.context?.descriptions,
        'SCHUKO': socketMachine.transition(socketMachine.initialState, { type: 'SCHUKO', payload: { identifier: 'type'} })?.context?.descriptions,
        'KLAPPDECKEL': socketMachine.transition(socketMachine.initialState, { type: 'KLAPPDECKEL', payload: { identifier: 'type'} })?.context?.descriptions,
        'UEBERSPANNUNGSSCHUTZ': socketMachine.transition(socketMachine.initialState, { type: 'UEBERSPANNUNGSSCHUTZ', payload: { identifier: 'type'} })?.context?.descriptions
    }
}

export default socketMachine