import { calculateDeviceForCPlate } from "./calculateDeviceForCplate"
import { calulatePossibleFrameSizes } from "./calculateFrame"

export const calculateFavorites = (deviceList, designRange, favorites, cplateMapping, frameMapping, additionalData) => {
    let calculatedFavorites = []
    /*if (designRange?.serie && designRange?.frameColor  && designRange?.cplateColor) {
        if (frameMapping && cplateMapping && favorites && deviceList.length > 0) {
            const possibleFrames = calulatePossibleFrameSizes(deviceList, designRange.serie, undefined, designRange.frameColor, 'H', frameMapping)
            
            favorites = favorites.filter(favorite => {
                return possibleFrames.find(
                    size => size === favorite.data.entities[favorite.data.rootEntityId].devices.length
                )
            })
            
            const articleColor = cplateMapping.find(el => {
                return el.serie === designRange.serie
                    && el.colorId === designRange?.cplateColor
            })?.articleColor
            
            if (articleColor) {
                let articleNumbers = []
                favorites.forEach(favorite => {
                    if (favorite?.data?.entities) {
                        Object.keys(favorite.data.entities).forEach(id => {
                            const entity = favorite.data.entities[id]
                            const parent = favorite.data.entities[entity.parent]
                            
                            if (parent && entity.availableDeviceId) {
                                if (!articleNumbers.find(el => el.identifier === entity.availableDeviceId)) {
                                    articleNumbers.push({
                                        identifier: entity.availableDeviceId,
                                        type: parent.type === 'operationPoint'
                                            ? 'einsatz'
                                            : entity.type === 'device'
                                                ? 'additionalDevice'
                                                : entity.position === 'left'
                                                    ? 'wippeLinks'
                                                    : entity.position === 'right'
                                                        ? 'wippeRechts'
                                                        : 'wippe',
                                        additionalArticles: parent.type !== 'operationPoint'
                                            && parent.devices
                                            && Array.isArray(parent.devices)
                                            ? parent.devices
                                                .filter(device => device.type === 'device')
                                                .map(device => device.availableDeviceId)
                                            : [],
                                        einsatz: parent.type !== 'operationPoint'
                                            ? parent.availableDeviceId
                                            : null,
                                    })
                                }
                            }
                        })
                       
                    }
                })

                articleNumbers.forEach(article => {
                    if (article.type === 'einsatz') {
                        article.availableDeviceId = article.identifier.replace('XX', articleColor)
                        const originDevice = deviceList.find(el => {
                            return el.getManufacturertypedescr() === article.availableDeviceId
                        })
                        if (originDevice) {
                            article.supplierPid = originDevice?.getSupplierpid()
                        }
                    } else {
                        article.availableDeviceId = article.identifier.replace('XX', articleColor)
                        const originDevice = deviceList.find(el => {
                            return el.getManufacturertypedescr() === article.availableDeviceId
                        })

                        if (originDevice) {
                            article.supplierPid = originDevice?.getSupplierpid()
                        } else {
                            article.availableDeviceId = article.identifier.replace('XX', articleColor)
                            const einsatz = deviceList.find(el => {
                                return el.getManufacturertypedescr() === article.einsatz
                            })
                            if (einsatz && article.type.includes('wippe')) {
                                const features = JSON.parse(einsatz.getFeatures())
                                const possibleDevices = calculateDeviceForCPlate(
                                    einsatz.getSupplierpid(),
                                    deviceList,
                                    features,
                                    articleColor,
                                    additionalData,
                                    article.additionalArticles,
                                    article.type === 'wippeLinks'
                                        ? 'left'
                                        : article.type === 'wippeRechts'
                                            ? 'right'
                                            : 'full',
                                    designRange?.serie
                                )
                                if (possibleDevices.length > 0) {
                                    let device = possibleDevices.find(dev => dev.IsDefault) || possibleDevices[0]
                                    article.supplierPid = device.supplierPid
                                } else {
                                    article.availableDeviceId = article.identifier.replace('XX', articleColor)
                                    const originDevice = deviceList.find(el => {
                                        return el.getManufacturertypedescr() === article.availableDeviceId
                                    })
                                    article.supplierPid = originDevice?.getSupplierpid()
                                }
                            }
                        }
                    }
                })

                calculatedFavorites = favorites.map(favorite => {
                    if (favorite?.data?.entities) {
                        Object.keys(favorite.data.entities).forEach(id => {
                            if (favorite.data.entities[id].availableDeviceId) {
                                articleNumbers.forEach(articleNumber => {
                                    if (articleNumber.identifier === favorite.data.entities[id].availableDeviceId) {
                                        favorite = {
                                            ...favorite,
                                            data: {
                                                ...favorite.data,
                                                entities: {
                                                    ...favorite.data.entities,
                                                    [id]: {
                                                        ...favorite.data.entities[id],
                                                        availableDeviceId: articleNumber.supplierPid,
                                                        onPiecelist: true
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })
                            }
                        })
                    }
                    return favorite
                })
            }
        }
    }*/
    return calculatedFavorites
}