import { useState, useEffect, useRef} from 'react';
import {createSelectorCreator, defaultMemoize} from 'reselect'
import isEqual from 'lodash/isEqual'

//eslint-disable-next-line no-extend-native
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export const createByteLengthEqualSelector = createSelectorCreator(defaultMemoize, (a, b) => {
  if (a?.byteLength && b?.byteLength) {
    return a.byteLength === b.byteLength
  } else {
    return a === b
  }
})

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
  
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay]
    );
  
    return debouncedValue;
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const capitalize = s => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}