import React from 'react';
import ReactDOM from 'react-dom';
//Toastify
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App/>, document.getElementById('root'));
serviceWorker.register();