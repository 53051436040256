import Dexie from "dexie";

const etimDB = new Dexie("etim");

etimDB.version(1).stores({ etim: "id, value" });

etimDB.on("populate", async function () {
  await etimDB.etim.add({ id: "fetchDate", value: null });
  await etimDB.etim.add({ id: "buffer", value: null });
  await etimDB.etim.add({ id: "checksum", value: null });
  await etimDB.etim.add({ id: "categoryLength", value: 0 });
});

etimDB.open();

export default etimDB;
