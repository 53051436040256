import v4 from 'node-uuid'
import { calculateDeviceForCPlate } from './calculateDeviceForCplate'
import { calulatePossibleFrameSizes } from './calculateFrame'
import { calculatePossibleDevice } from './calculatePossibleDevice'
import { calculateTouchProtectionForTemplates } from './calculateTouchProtection'

export const calculateTemplates = (
    deviceList,
    designRange,
    designInfomationen,
    calulatedTemplates,
    cplateMapping,
    frameMapping,
    additionalData
) => {
    let templates = []
    const elementTypes = ['additionalDevice', 'wippe', 'wippeLinks', 'wippeRechts']
    if (designRange?.serie && designRange?.frameColor && designRange?.cplateColor) {
        if (frameMapping && cplateMapping && calulatedTemplates && deviceList.length > 0) {
            const possibleFrames = calulatePossibleFrameSizes(
                deviceList,
                designRange.serie,
                undefined,
                designRange.frameColor,
                'H',
                frameMapping
            )

            calulatedTemplates = calulatedTemplates.filter(template => {
                if (!template?.combination?.length) {
                    return false
                }
                if (template?.exclude && designRange?.serie && template.exclude.find(id => id === designRange.serie)) {
                    return false
                }
                return possibleFrames.find(size => size === template.combination.length)
            })

            const articleColor = cplateMapping.find(el => {
                return el.serie === designRange.serie && el.colorId === designRange?.cplateColor
            })?.articleColor

            if (articleColor) {
                let articleNumbers = []
                calulatedTemplates.forEach(template => {
                    template.combination.forEach(combination => {
                        if (combination.einsatz) {
                            if (!articleNumbers.find(el => el.identifier === combination.einsatz)) {
                                articleNumbers.push({ identifier: combination.einsatz, type: 'einsatz' })
                            }
                        }

                        elementTypes.forEach(id => {
                            if (combination[id]) {
                                if (!articleNumbers.find(el => el.identifier === combination[id])) {
                                    articleNumbers.push({ 
                                        identifier: combination[id],
                                        type: id, 
                                        einsatz: combination.einsatz,
                                        additionalArticles: combination?.additionalDevice
                                            ? [combination.additionalDevice]
                                            : []
                                    })
                                }
                            }
                        })
                    })
                })

                articleNumbers.forEach(article => {
                    if (article.type === 'einsatz') {

                        article.availableDeviceId = article.identifier.replaceAll('XX', articleColor)

                        let originDevice = article.availableDeviceId.split(' || ').reduce((acc, identifier) => {
                            if (acc) {
                                return acc
                            }

                            const availableDeviceId = identifier.replace('XX', articleColor)
                            const dev = deviceList.find(el => {
                                return el.getManufacturertypedescr() === availableDeviceId
                            })

                            if (dev) {
                                article.availableDeviceId = availableDeviceId
                            }

                            return dev
                        }, null)
                        
                        article.supplierPid = originDevice?.getSupplierpid()

                        if (!originDevice) {
                            const possibleArticle = article.identifier.split('-')[0]
                            originDevice = deviceList.find(el => {
                                return el.getManufacturertypedescr().includes(`${possibleArticle}-`)
                            })

                            const features = originDevice?.getFeatures() ? JSON.parse(originDevice?.getFeatures()) : null
                            if (features) {
                                if (features[0]) {
                                    let filters = []
                                    const description = {
                                        feature: features[0].Id,
                                        manufacturerTypeDescr: '-' + articleColor,
                                        filters: [],
                                    }
                                    const possibleDevices = calculatePossibleDevice(
                                        deviceList,
                                        [{ ...description, filters }],
                                        additionalData,
                                        designRange?.serie
                                    )

                                    let bestPossibleDevice = {}
                                    if (possibleDevices.length > 0) {
                                        let bestMatches = 0
                                        possibleDevices.forEach(pDev => {
                                            let matches = 0
                                            const pFeatures = JSON.parse(pDev.features)
                                            pFeatures[0].Feature.forEach(feature => {
                                                if (
                                                    feature.Fname !== 'EF005474' && //Schutzart (IP)
                                                    feature.Fname !== 'EF004293' && //Schlagfestigkeit
                                                    feature.Fname !== 'EF002442' && //Befestigungsart
                                                    feature.Fname !== 'EF000008' && //Breite
                                                    feature.Fname !== 'EF000040' && //Höhe
                                                    feature.Fname !== 'EF000049' && //Tiefe
                                                    feature.Fname !== 'EF000846' && //Einbaubreite
                                                    feature.Fname !== 'EF000332' && //Einbauhöhe
                                                    feature.Fname !== 'EF009277'    //Durchmesser Bohrung (Öffnung)
                                                ) {
                                                    const matchedFeature = features[0].Feature.find(
                                                        feat =>
                                                            feat.Fname === feature.Fname &&
                                                            feat.Fvalue === feature.Fvalue
                                                    )
                                                    if (matchedFeature) {
                                                        matches++
                                                    }
                                                }
                                            })

                                            if (matches > bestMatches) {
                                                bestPossibleDevice = pDev
                                                bestMatches = matches
                                            }
                                        })
                                    }
                                    if (bestPossibleDevice?.supplierPid) {
                                        article.availableDeviceId = bestPossibleDevice.manufacturerTypeDescr
                                        article.supplierPid = bestPossibleDevice.supplierPid
                                        originDevice = deviceList.find(el => {
                                            return (
                                                el.getManufacturertypedescr() ===
                                                bestPossibleDevice.manufacturerTypeDescr
                                            )
                                        })
                                    }
                                }
                            }
                        }

                        const features = originDevice && JSON.parse(originDevice?.getFeatures())

                        if (features) {
                            if (features[0] && features[0].Id === 'EC000125') { //Steckdose
                                article = calculateTouchProtectionForTemplates(
                                    article,
                                    deviceList,
                                    articleColor,
                                    features[0].Feature,
                                    designInfomationen.touchProtection,
                                    additionalData
                                )
                            } else if (features[0] && features[0].Id === 'EC001264') { //Kommunikationsanschlussdose Kupfer
                                let steckverbindung = features[0].Feature.find(el => el.Fname === 'EF002935') //Steckverbindertyp
                                if (
                                    steckverbindung &&
                                    (steckverbindung.Fvalue === 'EV003197' || //2x RJ45 8(4)
                                        steckverbindung.Fvalue === 'EV000013' || //RJ45 8(8)
                                        steckverbindung.Fvalue === 'EV006999') //2x RJ45 8(8)
                                ) {
                                    const description = {
                                        feature: 'EC001264', //Kommunikationsanschlussdose Kupfer
                                        filters: [
                                            {
                                                Id: 'EF000005', //Zusammenstellung
                                                Value: 'EV000078', //Basiselement
                                            },
                                            {
                                                Id: 'EF001579', //Geeignet für Anzahl Buchsen/Kupplungen
                                                Value: `${designInfomationen.dataSockets === 'Simplex' ? 1 : 2}`,
                                            },
                                            {
                                                Id: 'EF002935', //Steckverbindertyp
                                                //EV003197 2x RJ45 8(4)
                                                //EV000013 RJ45 8(8)
                                                //EV006999 2x RJ45 8(8)
                                                Value: ['EV003197', 'EV000013', 'EV006999'],
                                            },
                                        ],
                                    }
                                    const possibleDevices = calculatePossibleDevice(deviceList, [description], undefined, designRange?.serie)
                                    const possibleDevice = possibleDevices[0]
                                    if (possibleDevice) {
                                        article.supplierPid = possibleDevice.supplierPid
                                        const cplates = calculateDeviceForCPlate(
                                            possibleDevice.supplierPid,
                                            deviceList,
                                            JSON.parse(possibleDevice.features),
                                            articleColor,
                                            additionalData,
                                            article.additionalArticles,
                                            'full',
                                            designRange?.serie
                                        )
                                        if (cplates?.length > 0) {
                                            const cplate = cplates.find(el => el.IsDefault) || cplates[0]
                                            article.wippe = cplate.supplierPid
                                        }
                                    }
                                }
                            } else if (features[0] && features[0].Id === 'EC000439') { //Antennensteckdose
                                if (designInfomationen.satSockets === '4-Loch Ankaro') {
                                    article.supplierPid = 'Ankaro'
                                    const cplates = calculateDeviceForCPlate(
                                        article.supplierPid,
                                        deviceList,
                                        [{ Id: 'Ankaro' }],
                                        articleColor,
                                        additionalData,
                                        [],
                                        'full',
                                        designRange?.serie
                                    )
                                    if (cplates?.length > 0) {
                                        const cplate = cplates.find(el => el.IsDefault) || cplates[0]
                                        article.wippe = cplate.supplierPid
                                    }
                                } else if (designInfomationen.satSockets === '4-Loch Hirschmann') {
                                    article.supplierPid = 'Hirschmann'
                                    const cplates = calculateDeviceForCPlate(
                                        article.supplierPid,
                                        deviceList,
                                        [{ Id: 'Hirschmann' }],
                                        articleColor,
                                        additionalData,
                                        [],
                                        'full',
                                        designRange?.serie
                                    )
                                    if (cplates?.length > 0) {
                                        const cplate = cplates.find(el => el.IsDefault) || cplates[0]
                                        article.wippe = cplate.supplierPid
                                    }
                                }
                            }
                        }
                    } else {
                        article.availableDeviceId = article.identifier.replace('XX', articleColor)
                        const originDevice = deviceList.find(el => {
                            return el.getManufacturertypedescr() === article.availableDeviceId
                        })

                        

                        if (originDevice) {
                            article.supplierPid = originDevice?.getSupplierpid()
                        } else {
                            console.warn(`missing article: ${article.availableDeviceId}`)
                            article.availableDeviceId = article.identifier.replace('XX', articleColor)
                            const einsatz = deviceList.find(el => {
                                return el.getManufacturertypedescr() === article.einsatz
                            })
                            if (einsatz && article.type.includes('wippe')) {
                                const features = JSON.parse(einsatz.getFeatures())
                                
                                const possibleDevices = calculateDeviceForCPlate(
                                    einsatz.getSupplierpid(),
                                    deviceList,
                                    features,
                                    articleColor,
                                    additionalData,
                                    article.additionalArticles,
                                    article.type === 'wippeLinks'
                                    ? 'left'
                                    : article.type === 'wippeRechts'
                                        ? 'right'
                                        : 'full',
                                    designRange?.serie
                                )
                                if (possibleDevices.length > 0) {
                                    let device = possibleDevices.find(dev => dev.IsDefault) || possibleDevices[0]
                                    article.supplierPid = device.supplierPid
                                } else {
                                    article.availableDeviceId = article.identifier.replace('XX', articleColor)
                                    const originDevice = deviceList.find(el => {
                                        return el.getManufacturertypedescr() === article.availableDeviceId
                                    })
                                    article.supplierPid = originDevice?.getSupplierpid()
                                }
                            }
                        }
                    }
                })


                templates = calulatedTemplates.filter(template => {
                    let chk = template.deviceType === 'both'
                        || designInfomationen.deviceType === template.deviceType

                    template.combination.forEach(comb => {
                        let combination = JSON.parse(JSON.stringify(comb))
                        let devices = []
                        articleNumbers.forEach(articleNumber => {
                            if (articleNumber.identifier === combination.einsatz
                                || articleNumber.identifier === combination.additionalDevice
                            ) {
                                    if (articleNumber?.supplierPid) {
                                        devices.push(articleNumber)
                                    }
                            }  
                        })


                        /*chk =  chk || devices.reduce((val, dev) => {
                            if (dev.type !== 'einsatz') {
                                return val
                            }
                            const additionalDeviceData = additionalData.find(el => el.supplier_pid === dev.supplierPid)
                            if (additionalDeviceData && designInfomationen?.deviceType !== undefined) {
                                return val
                                    || ((designInfomationen.deviceType === 'conventional' && !additionalDeviceData.flex)
                                        || (designInfomationen.deviceType === 'flex' && additionalDeviceData.flex))
                            }
                            return val || designInfomationen.deviceType === 'conventional'
                        }, false)*/
                    })
                    return chk
                }).map(template => {                    
                    let returnObj = {
                        rootEntityId: v4(),
                        onStart: template.onStart,
                        orientation: template.orientation,
                        name: template.name,
                        entities: {},
                    }

                    let operationPoint = {
                        id: returnObj.rootEntityId,
                        type: 'operationPoint',
                        devices: [],
                    }

                    template.combination.forEach(comb => {
                        let combination = JSON.parse(JSON.stringify(comb))
                        let einsatz = {
                            id: v4(),
                            parent: operationPoint.id,
                            type: 'device',
                            subtype: 'device',
                            devices: [],
                            onPiecelist: true,
                        }
                        articleNumbers.forEach(articleNumber => {
                            if (articleNumber.identifier === combination.einsatz) {
                                einsatz.availableDeviceId = articleNumber.supplierPid
                                if (articleNumber.wippe) {
                                    combination.wippe = articleNumber.wippe
                                }
                            }
                        })
                        
                        elementTypes.forEach(id => {
                            if (combination[id]) {
                                let device = {
                                    id: v4(),
                                    parent: einsatz.id,
                                    type: 'device',
                                    subtype: id === 'additionalDevice'
                                        ? 'device'
                                        : 'cplate',
                                    position: id === 'wippeLinks'
                                            ? 'left'
                                            : id === 'wippeRechts'
                                                ? 'right'
                                                : 'full',
                                    onPiecelist: true,
                                }
    
                                if (combination[id].includes('2CKA')) {
                                    device.availableDeviceId = combination[id]
                                } else {
                                    articleNumbers.forEach(articleNumber => {
                                        if (articleNumber.identifier === combination[id]) {
                                            device.availableDeviceId = articleNumber.supplierPid
                                        }
                                    })
                                }
    
                                einsatz.devices.push(device.id)
                                returnObj.entities[device.id] = device
                            }
                        })

                        returnObj.entities[einsatz.id] = einsatz
                        operationPoint.devices.push(einsatz.id)
                    })
                    returnObj.entities[operationPoint.id] = operationPoint
                    return returnObj
                })
            }
        }
    }

    return templates
}
