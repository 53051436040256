import { createDeepEqualSelector } from '../../vendor/qlib'
import { createSelector } from 'reselect'
import axios from 'axios'

import building from './building/duck'
import floor from './floor/duck'
import room from './room/duck'
import operationPoint, { getImageDataFromOperationPoint, getOrientation } from './operationPoint/duck'
import device from './device/duck'
import { generateCloneSequence, generateDeleteSequence } from './helpers'
import { batchActions } from 'redux-batched-actions'
import { getDesignInformationFromProject, getEditableProject } from '../projects/duck'
import { equals } from 'ramda'
import {
    getAdditionalData,
    getCalculatedTemplates,
    getCplateMapping,
    getDeviceList,
    getDevicesWithoutFrame,
    getFrameMapping,
    getSynonyms,
    getCplate
} from '../availableDevices/duck'
import {calculateChangeDesignRange} from './helpers/changeDesignRange'
import { atomWithReducer } from 'jotai/utils'
import { atom } from 'jotai'
import { enableBatchReducer } from '../../vendor/enableBatchReducer'
import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { calculateTemplates } from '../../logic/calculateTemplate'
import { calculateTouchProtection } from './helpers/calculateTouchProtection'
import { updateOnPiecelist } from './helpers/updateOnPiecelist'
import { getFavorites } from '../favorites/duck'
import { calculateFavorites } from '../../logic/calculateFavorites'

import {possibleBlindClasses} from '../possibleDevices/machines/blind'
import {possibleDataClasses} from '../possibleDevices/machines/data'
import {possibleDimmerClasses} from '../possibleDevices/machines/dimmer'
import {possibleMotionClasses} from '../possibleDevices/machines/motion'
import {possibleRotationClasses} from '../possibleDevices/machines/rtr'
import {possibleSocketClasses} from '../possibleDevices/machines/socket'
import {possibleSwitchClasses} from '../possibleDevices/machines/switch'
import {possibleDoorCommunicationClasses} from '../possibleDevices/machines/doorCommunication'

export const CLEAR_ENTITIES = 'raumplaner/entities/CLEAR_ENTITIES'
export const SET_ENTITIES = 'raumplaner/entities/SET_ENTITIES'
export const SET_ENTITY_LABEL = 'raumplaner/entities/SET_ENTITY_LABEL'
export const SET_CONFIG_VALUE = 'raumplaner/entities/SET_CONFIG_VALUE'

const entityInitialState = { byId: {}, allIds: [] }

const entityReducer = (state = entityInitialState, action) => {
    let configIndex
    switch (action.type) {
        case SET_ENTITIES:
            return {
                ...state,
                byId: action.byId,
                allIds: action.allIds,
            }
        case CLEAR_ENTITIES:
            return { ...entityInitialState }
        case SET_ENTITY_LABEL:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        label: action.label,
                    },
                },
            }
        case SET_CONFIG_VALUE:
            configIndex = state.byId[action.id].config.findIndex(el => el.type === action.config.type)
            if (configIndex !== -1) {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.id]: {
                            ...state.byId[action.id],
                            config: [
                                ...state.byId[action.id].config.slice(0, configIndex),
                                ...state.byId[action.id].config.slice(configIndex + 1),
                                action.config,
                            ]
                        },
                    }
                }
            }
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        config: [...state.byId[action.id].config, action.config],
                    }
                }
            }
        default:
            return state
    }
}

const reducer = (state = { byId: {}, allIds: [] }, action) => {
    return entityReducer(
        building(floor(room(operationPoint(device(state, action), action), action), action), action),
        action
    )
}

export const entitiesAtom = atomWithReducer(
    JSON.parse(localStorage.getItem('entities')) ?? entityInitialState,
    (state, action) => enableBatchReducer(state, action, reducer)
)

export const entitiesAtomWithPersistence = atom(
    (get) => get(entitiesAtom),
    (get, set, action) => {
        set(entitiesAtom, action)
        localStorage.setItem('entities', JSON.stringify(get(entitiesAtom)))
    }
)

export default reducer

export const entityAttributes = ['buildings', 'floors', 'rooms', 'operationPoints', 'devices', 'frame']

export const clearEntities = () => {
    return {
        type: CLEAR_ENTITIES,
    }
}

export const setEntities = (entities = {}) => {
    return {
        type: SET_ENTITIES,
        byId: entities,
        allIds: Object.keys(entities),
    }
}

export const setEntityLabel = (id, label) => {
    return {
        type: SET_ENTITY_LABEL,
        id,
        label,
    }
}

export const setConfigValue = (id, config) => {
    return {
        type: SET_CONFIG_VALUE,
        id,
        config,
    }
}

export const deleteEntity = entity => {
    return (dispatch, getState) => {
        dispatch(generateDeleteSequence(getState(), entity))
    }
}

export const cloneEntity = entity => {
    return (dispatch, getState) => {
        let actions = generateCloneSequence(getState(), entity)
        return dispatch(batchActions(actions))
    }
}
export const changeDesignRange = (serieId, frameColorId, cPlateColorId, entityId = null) =>{
    return (dispatch, getState) => {
        calculateChangeDesignRange({state: getState(), serieId, frameColorId, cPlateColorId, entityId}).then(data => {
            dispatch(data.action)
        })
    }   
}

export const changeTouchProtection = touchProtection => {
    return (dispatch, getState) => {
        calculateTouchProtection(dispatch, getState, touchProtection)
    }   
}

export const calculateOnPiecelist = onPiecelist => {
    return (dispatch, getState) => {
        updateOnPiecelist(dispatch, getState, onPiecelist)
    }   
}

export const getAllEntities = state => state.entities.byId
export const getAllEntityIds = state => state.entities.allIds
export const getAllEntitiesLength = state => state.entities.allIds.length

export const getEntity = (state, entityId) => getAllEntities(state)[entityId]

export const getEntityResolved = (state, entityId) => {
    return createDeepEqualSelector([getAllEntities, getDeviceList], (entities, deviceList) => {
        return resolve(getEntity(state, entityId), entities, deviceList)
    })(state)
}

export const _resolveDevice = (unresolvedDevice, deviceList) => {
    if (unresolvedDevice) {
        return {
            ...unresolvedDevice,
            //originDevice: deviceList.find(dev => dev.getSupplierpid() === unresolvedDevice.availableDeviceId),
        }
    }
    return null
}

export const resolve = (entity, entities, deviceList) => {
    if (!entity) {
        return null
    }

    entity = { ...entity }
    for (let i = 0; i < entityAttributes.length; i++) {
        let entityAttribute = entityAttributes[i]
        if (entityAttribute !== 'frame' && entity.hasOwnProperty(entityAttribute) && entity[entityAttribute].length > 0) {
            entity[entityAttribute] = entity[entityAttribute].map(entityId => {
                const index = entity[entityAttribute] ? entity[entityAttribute].indexOf(entityId) : -1
                return {
                    ...resolve(entities[entityId], entities, deviceList),
                    index,
                }
            })
        }
    }

    if (entity.hasOwnProperty('parent')) {
        entity.parent = entities[entity.parent]
    }

    if (entity.hasOwnProperty('frame')) {
        entity.frame = {
            ...resolve(entities[entity.frame], entities, deviceList),
        }
    }

    return entity.type === 'device' || entity.type === 'frame' ? _resolveDevice(entity, deviceList) : entity
}

export const getRootEntityId = createSelector([getEditableProject], (project) => {
    return project?.data?.rootEntityId
})

export const getRootEntity = createSelector([getEditableProject, getAllEntities], (project, entities) => {
    if (project?.data?.rootEntityId) {
        return entities[project.data.rootEntityId]
    }
    return null
})

export const getRootEntityResolved = createDeepEqualSelector(
    [getRootEntity, getAllEntities, getDeviceList],
    (rootEntity, entities, deviceList) => {
        if (rootEntity) {
            return resolve(rootEntity, entities, deviceList)
        }
        return null
    }
)

export const getFloors = createDeepEqualSelector([getRootEntityResolved], rootEntity => {
    if (rootEntity) {
        return rootEntity.floors
    }
    return []
})

export const getActiveFloor = createDeepEqualSelector([getRootEntityResolved], rootEntity => {
    if (rootEntity) {
        return rootEntity.floors.find(floor => floor.id === rootEntity.activeFloor)
    }
    return null
})

export const getActiveRoom = createDeepEqualSelector([getActiveFloor], activeFloor => {
    if (activeFloor) {
        return activeFloor.rooms.find(room => room.id === activeFloor.activeRoom)
    }
    return null
})

export const getDesignRangeForActiveRoom = (state) => {
    const activeRoom = getActiveRoom(state)
    if (!activeRoom?.id) {
        return null
    }
    return getParentDesignRange(state, activeRoom.id)
}

export const getAllDevices = createDeepEqualSelector(
    [getRootEntityResolved, getAllEntities, getDeviceList],
    (rootEntity, entities, deviceList) => {
        if (rootEntity) {
            const devices = []
            rootEntity.floors.forEach(floor => {
                floor.rooms.forEach(room => {
                    room.operationPoints.forEach(op => {
                        const isUpdating = op.isUpdating
                        const roomId = op.parent.id
                        for (let i = 0; i < op.quantity; i++) {
                            op.devices.forEach(device => {
                                devices.push({ ...device, roomId, isUpdating })
                                if (device?.devices) {
                                    device.devices.forEach(device => devices.push({ ...device, roomId, isUpdating }))
                                }
                            })
                            devices.push({ ...op.frame, roomId, isUpdating })
                        }
                    })
                })
            })
                        
            return devices
        }
        return []
    }
)

export const getAllOperationPoints = createDeepEqualSelector(
    [getRootEntityResolved, getAllEntities],
    (rootEntity, entities) => {
        if (rootEntity) {
            let operationPoints = []
            rootEntity.floors.forEach(floor => {
                floor.rooms.forEach(room => {
                    operationPoints = operationPoints.concat(room.operationPoints)
                })
            })

            return operationPoints
        }
        return []
    }
)

export const getOperationPointsFromEntity = (state, entityId) => {
    const entities = getAllEntities(state)
    let entity = entityId ? entities[entityId] : null
    let operationPoints = []
    if (entity) {
        const entityResolved = getEntityResolved(state, entityId)
        if (entityResolved.type === 'operationPoint') {
            operationPoints.push(entityResolved)
        } else if (entityResolved.type === 'room') {
            operationPoints = operationPoints.concat(entityResolved.operationPoints)
        } else if (entityResolved.type === 'floor') {
            entityResolved.rooms.forEach(room => {
                operationPoints = operationPoints.concat(room.operationPoints)
            })
        } else if (entityResolved.type === 'building') {
            entityResolved.floors.forEach(floor => {
                floor.rooms.forEach(room => {
                    operationPoints = operationPoints.concat(room.operationPoints)
                })
            })
        }
    }
    return operationPoints
}

export const getConfiguratedDevices = createDeepEqualSelector([getAllDevices, getDeviceList], (allDevices, deviceList) => {
    const devices = mapConfiguratedDevices(allDevices, deviceList, true)
    return devices
})

export const mapConfiguratedDevices = (devices, deviceList, byRoom) => {
    let configuredDevices = []
    devices.forEach((dev, i) => {
        if (dev.onPiecelist) {
            const index = configuredDevices.findIndex(
                el =>
                    (byRoom && el.availableDeviceId === dev.availableDeviceId && el.roomId === dev.roomId) ||
                    (!byRoom && el.availableDeviceId === dev.availableDeviceId)
            )
            if (index !== -1) {
                configuredDevices[index] = {
                    ...configuredDevices[index],
                    quantity: configuredDevices[index].quantity + dev.quantity,
                }
            } else {
                configuredDevices.push({
                    availableDeviceId: dev.availableDeviceId,
                    roomId: dev.roomId,
                    quantity: dev.quantity,
                    originDevice: deviceList.find(el => el.getSupplierpid() === dev.availableDeviceId),
                    onPiecelist: dev.onPiecelist,
                })
            }
        }
    })

    return configuredDevices
}

export const getTotalPriceFromConfiguratedDevices = createDeepEqualSelector([getConfiguratedDevices], (devices) => {
    let totalSum = 0
    devices.forEach((device, index) => {
        if (device.originDevice) {
            let s =
                (device.originDevice
                    ?.getPricedetails()
                    ?.getPricesList()[0]
                    ?.getAmount() || 0) * device.quantity 
            totalSum += s
        }
    })
    return Math.round(totalSum * 100) / 100
})

export const getEntitiesFromOperationPoint = (entities, operationPointId) => {
    let opEntities = {}
    const operationPoint = entities[operationPointId]

    if (operationPoint) {
        opEntities[operationPoint.id] = operationPoint
        if (operationPoint.devices) {
            operationPoint.devices.forEach(deviceId => {
                const device = entities[deviceId]
                if (device) {
                    opEntities[device.id] = device
                    if (device.devices) {
                        device.devices.forEach(cplateId => {
                            const cplate = entities[cplateId]
                            if (cplate) {
                                opEntities[cplate.id] = cplate
                            }
                        })
                    }
                }
            })
        }
    }
    return opEntities
}

export const getHasOperationPointError = (rootEntityId, roomEntities, projectId, devicesWithoutFrame) => {
    let hasError = false
    const rootEntity = roomEntities[rootEntityId]
    if (rootEntity) {
        const frameSize = rootEntity.devices.map(id => roomEntities[id]).filter(el => {
            return el && !devicesWithoutFrame.find(el2 => el2 === el.availableDeviceId)
        }).length
        
        if (frameSize > 0 && !rootEntity.frame) {
            hasError = true
        }
    }    
    return hasError
}

export const getCalculatedFavorites = createDeepEqualSelector(
    [getDeviceList, getDesignRangeForActiveRoom, getFavorites, getCplateMapping, getFrameMapping, getAdditionalData],
    (deviceList, designRange, favorites, cplateMapping, frameMapping, additionalData) => {
        return calculateFavorites(deviceList, designRange, favorites, cplateMapping, frameMapping, additionalData)
    }
)

export const getAvailableTemplates = createDeepEqualSelector(
    [getDeviceList, getDesignRangeForActiveRoom, getDesignInformationFromProject, getCalculatedTemplates, getCplateMapping, getFrameMapping, getAdditionalData],
    (deviceList, designRange, designInfomationen, calculatedTemplates, cplateMapping, frameMapping, additionalData) => {
        return calculateTemplates(deviceList, designRange, designInfomationen, calculatedTemplates, cplateMapping, frameMapping, additionalData)
    }
)

export const getAvailableCombinations = createSelector(
    [getEditableProject, getAvailableTemplates, getActiveRoom, getAllEntities, getCalculatedFavorites, getDevicesWithoutFrame],
    (project, calculatedTemplates, activeRoom, entities, favorites, devicesWithoutFrame) => {
        let operationPoints = []
        if (!project) {
            return operationPoints
        }
        if (activeRoom) {
            operationPoints = activeRoom.operationPoints.map(el => {
                let roomEntities = getEntitiesFromOperationPoint(entities, el.id)
                return {
                    ...el,
                    rootEntity: el.id,
                    imgData: getImageDataFromOperationPoint(project, entities, el.id, el.orientation),
                    entities: roomEntities,
                    orientation: getOrientation(el),
                    hasError: getHasOperationPointError(el.id, roomEntities, project, devicesWithoutFrame),
                }
            })
        }

        favorites = favorites.map(el => {
            let imgData = getImageDataFromOperationPoint(project, el.data.entities, el.data.rootEntityId, el.orientation)
            let operationPoint = operationPoints.find(op => equals(op.imgData, imgData))
            return {
                ...el.data,
                imgData,
                quantity: operationPoint ? operationPoint.quantity : 0,
                orientation: operationPoint ? getOrientation(operationPoint) : el.orientation || 'V',
                isFavorite: true,
                favoriteId: el.ID,
            }
        })

        calculatedTemplates = calculatedTemplates
            //.filter(el => !el.onStart)
            .map(el => {
                let imgData = getImageDataFromOperationPoint(project, el.entities, el.rootEntityId, el.orientation)
                let operationPoint = operationPoints.find(op => {
                    return equals(op.imgData, imgData)
                })
                return {
                    ...el,
                    imgData,
                    rootEntityId: el.rootEntityId,
                    orientation: operationPoint ? getOrientation(operationPoint) : el.orientation || 'V',
                    quantity: operationPoint ? operationPoint.quantity : 0,
                }
            })

        return [
            ...calculatedTemplates.filter(el => {
                return (
                    !favorites.find(el2 => equals(el.imgData, el2.imgData)) &&
                    !operationPoints.find(el2 => equals(el.imgData, el2.imgData))
                )
            }),
        ]
    }
)

const getStartCombinations = (project, calculatedTemplates, activeRoom, entities, favorites, devicesWithoutFrame, additionalData, designInfomationen) => {
    let operationPoints = []
    if (!project) {
        return operationPoints
    }
    if (activeRoom) {
        operationPoints = activeRoom.operationPoints.filter(el => {
            let roomEntities = getEntitiesFromOperationPoint(entities, el.id)
            return Object.keys(roomEntities).reduce((val, key) => {
                const dev = roomEntities[key]
                if (dev.type === 'operationPoint' || dev.type === 'cplate') {
                    return val
                }
                /*const additionalDeviceData = additionalData.find(el => el.supplier_pid === dev.availableDeviceId)
                if (additionalDeviceData && designInfomationen?.deviceType !== undefined) {
                    return val
                        || ((designInfomationen.deviceType === 'conventional' && !additionalDeviceData.flex)
                            || (designInfomationen.deviceType === 'flex' && additionalDeviceData.flex))
                }
                return val || designInfomationen.deviceType === 'conventional'*/
                return val
            }, true)
        }).map(el => {    
            let roomEntities = getEntitiesFromOperationPoint(entities, el.id)
            return {
                ...el,
                rootEntityId: el.id,
                imgData: getImageDataFromOperationPoint(project, entities, el.id, el.orientation),
                entities: roomEntities,
                orientation: getOrientation(el),
                isUpdating: el.isUpdating,
                hasError: getHasOperationPointError(el.id, roomEntities, project, devicesWithoutFrame),
            }
        })
    }

    /*favorites = favorites.map(el => {
        let imgData = getImageDataFromOperationPoint(project, el.data.entities, el.data.rootEntityId, el.orientation)
        let operationPoint = operationPoints.find(op => equals(op.imgData, imgData))
        return {
            ...el.data,
            imgData,
            quantity: operationPoint ? operationPoint.quantity : 0,
            isFavorite: true,
            favoriteId: el.ID,
            orientation: operationPoint ? getOrientation(operationPoint) : el.orientation || 'V',
            isUpdating: operationPoint ? operationPoint.isUpdating : false
        }
    })*/

    calculatedTemplates = calculatedTemplates
        .filter(el => el.onStart)
        .map(el => {
            let imgData = getImageDataFromOperationPoint(project, el.entities, el.rootEntityId, el.orientation)
            let operationPoint = operationPoints.find(op => {
                return equals(op.imgData, imgData)
            })
            return {
                ...el,
                imgData,
                rootEntityId: operationPoint ? operationPoint.id : el.rootEntityId,
                entities: operationPoint ? operationPoint.entities : el.entities,
                quantity: operationPoint ? operationPoint.quantity : 0,
                orientation: operationPoint ? getOrientation(operationPoint) : el.orientation || 'V',
                isUpdating: operationPoint ? operationPoint.isUpdating : false
            }
        })

    return {
        //favorites,
        calculatedTemplates,
        operationPoints,
    }
}

export const getAvailableStartCombinations = createSelector(
    [getEditableProject, getAvailableTemplates, getActiveRoom, getAllEntities, getCalculatedFavorites, getDevicesWithoutFrame, getAdditionalData, getDesignInformationFromProject],
    (project, calculatedTemplates, activeRoom, entities, favorites, devicesWithoutFrame, additionalData, designInfomationen) => {
        const data = getStartCombinations(project, calculatedTemplates, activeRoom, entities, favorites, devicesWithoutFrame, additionalData, designInfomationen)        
        return [
            //...data.favorites,
            ...data.calculatedTemplates/*.filter(el => {
                return !data.favorites.find(el2 => equals(el.imgData, el2.imgData))
            })*/,
            ...data.operationPoints.filter(el => {
                return !data.calculatedTemplates.find(el2 => equals(el.imgData, el2.imgData))
                    // && !data.favorites.find(el2 => equals(el.imgData, el2.imgData))
            })
        ]
    }
)

export const getAvailableSuggestions = createSelector(
    [getEditableProject, getAvailableTemplates, getActiveRoom, getAllEntities, getCalculatedFavorites, getDevicesWithoutFrame, getAdditionalData, getDesignInformationFromProject],
    (project, calculatedTemplates, activeRoom, entities, favorites, devicesWithoutFrame, additionalData, designInfomationen) => {
        const data = getStartCombinations(project, calculatedTemplates, activeRoom, entities, favorites, devicesWithoutFrame, additionalData, designInfomationen)        
        return [
            //...data.favorites,
            ...data.calculatedTemplates/*.filter(el => {
                return !data.favorites.find(el2 => equals(el.imgData, el2.imgData))
            })*/,
        ]
    }
)

export const getAvailableOperationPoints = createSelector(
    [getEditableProject, getAvailableTemplates, getActiveRoom, getAllEntities, getCalculatedFavorites, getDevicesWithoutFrame, getAdditionalData, getDesignInformationFromProject],
    (project, calculatedTemplates, activeRoom, entities, favorites, devicesWithoutFrame, additionalData, designInfomationen) => {
        const data = getStartCombinations(project, calculatedTemplates, activeRoom, entities, favorites, devicesWithoutFrame, additionalData, designInfomationen)        
        return [
            ...data.operationPoints
        ]
    }
)

export const getAvailableOperationPointsLength = createSelector(
    [getAvailableOperationPoints],
    (combinations) => {
        return combinations.length
    }
)

export const getAvailableStartCombination = (state, id) => {
    return createDeepEqualSelector(
        [getAvailableStartCombinations],
        startCombinations => {
            return startCombinations.find(el => el.rootEntityId === id)
        }
    )(state)
}

export const getAvailableSeries = createDeepEqualSelector([], () => {
    return [
        {
            id: 28,
            name: 'future® linear',
            cplateColor: 37,
            frameColor: 37,
            deviceId: '2CKA001751A3027',
        },
        {
            id: 52,
            name: 'Busch-balance® SI',
            cplateColor: 22,
            frameColor: 22,
            deviceId: '2CKA001731A2002',
        },
        {
            id: 53,
            name: 'Reflex SI',
            cplateColor: 22,
            frameColor: 22,
            deviceId: '2CKA001731A0876',
        },
        {
            id: 60,
            name: 'Reflex SI Linear',
            cplateColor: 22,
            frameColor: 22,
            deviceId: '2CKA001731A0876',
        },
        {
            id: 21,
            name: 'Busch-axcent®',
            cplateColor: 37,
            frameColor: 7,
            deviceId: '2CKA001751A3027',
        },
        {
            id: 59,
            name: 'Busch-Duro 2000® SI',
            cplateColor: 51,
            frameColor: 51,
            deviceId: '2CKA001731A0223',
        },
        {
            id: 58,
            name: 'Busch-Duro 2000® SI Linear',
            cplateColor: 51,
            frameColor: 51,
            deviceId: '2CKA001731A0223',
        },
        {
            id: 26,
            name: 'carat®',
            cplateColor: 37,
            frameColor: 47,
            deviceId: '2CKA001751A3027',
        },
        {
            id: 29,
            name: 'solo®',
            cplateColor: 37,
            frameColor: 56,
            deviceId: '2CKA001751A3027',
        },
        {
            id: 30,
            name: 'pur edelstahl',
            cplateColor: 10,
            frameColor: 10,
            deviceId: '2CKA001751A2956',
        },
        {
            id: 25,
            name: 'Busch-dynasty®',
            cplateColor: 35,
            frameColor: 18,
            deviceId: '2CKA001751A2741',
        },
        /*{
            id: 63,
            name: 'Allwetter 44®',
            cplateColor: 22,
            frameColor: 22,
            deviceId: '2CKA001731A1775',
        },*/
        {
            id: 64,
            name: 'Busch-art linear®',
            cplateColor: 37,
            frameColor: 37,
            deviceId: '2CKA001710A4248',
        },
    ]
})

export const getAvailableSeriesWithImgData = createDeepEqualSelector([getAvailableSeries], series => {
    return series.map(serie => {
        return {
            ...serie,
            imgData: {
                name: 'Muster',
                switchRangeId: serie.id,
                switchColorId: serie.cplateColor,
                switchFrameColorId: serie.frameColor,
                height: 200,
                groups: [
                    {
                        frameTypeId: 1,
                        devices: [
                            {
                                frameHeight: 1,
                                products: [
                                    {
                                        orderNumber: serie.deviceId,
                                        quantity: 1,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            }
        }
    })
})

export const getSeriesImages = createDeepEqualSelector([getAvailableSeries], series => {
    let seriesImages = []
    return series
        .reduce((promise, serie, index) => {
            const data = {
                name: 'Muster',
                switchRangeId: serie.id,
                switchColorId: serie.cplateColor,
                switchFrameColorId: serie.frameColor,
                height: 200,
                groups: [
                    {
                        frameTypeId: 1,
                        devices: [
                            {
                                frameHeight: 1,
                                products: [
                                    {
                                        orderNumber: serie.deviceId,
                                        quantity: 1,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            }
            return promise.then(img => {
                if (index > 0) {
                    seriesImages.push(img)
                }
                return fetchImage(data)
            })
        }, Promise.resolve())
        .then(img => {
            seriesImages.push(img)
            return seriesImages
        })
})

export const getCategories = createDeepEqualSelector([], () => {
    return [
        {
            switchRangeId: 28,
            name: 'Schalter/Taster',
            cplateColor: 37,
            frameColor: 37,
            deviceId: '2CKA001751A3027',
        },
        {
            switchRangeId: 28,
            name: 'Steckdosen',
            cplateColor: 37,
            frameColor: 37,
            deviceId: '2CKA002011A3725',
        },
        {
            switchRangeId: 28,
            name: 'Jalousien',
            cplateColor: 37,
            frameColor: 37,
            deviceId: '2CKA001751A2756',
        },
        {
            switchRangeId: 28,
            name: 'Datenkommunikation',
            cplateColor: 37,
            frameColor: 37,
            deviceId: '2CKA001710A3164',
        },
        {
            switchRangeId: 28,
            name: 'Dimmer',
            cplateColor: 37,
            frameColor: 37,
            deviceId: '2CKA006599A2953',
        },
        {
            switchRangeId: 28,
            name: 'Bewegungsmelder',
            cplateColor: 37,
            frameColor: 37,
            deviceId: '2CKA006800A2101',
        },
    ]
})

export const getCategoriesImages = createDeepEqualSelector([getCategories], cats => {
    let catsImages = []
    return cats
        .reduce((promise, cat, index) => {
            const data = {
                name: 'Muster',
                switchRangeId: cat.switchRangeId,
                switchColorId: cat.cplateColor,
                switchFrameColorId: cat.frameColor,
                height: 200,
                groups: [
                    {
                        frameTypeId: 1,
                        devices: [
                            {
                                frameHeight: 1,
                                products: [
                                    {
                                        orderNumber: cat.deviceId,
                                        quantity: 1,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            }
            return promise.then(img => {
                if (index > 0) {
                    catsImages.push(img)
                }
                return fetchImage(data)
            })
        }, Promise.resolve())
        .then(img => {
            catsImages.push(img)
            return catsImages
        })
})

// Helpers
export const fetchImage = data => {
    return axios
        .post(`${process.env.REACT_APP_TERMINAL_API_URL}/api/image`, data, {
            responseType: 'arraybuffer',
        })
        .then(response => {
            return 'data:img/png;base64,' + new Buffer(response.data, 'binary').toString('base64')
        })
}

const possibleClasses = [
    possibleSwitchClasses,
    possibleBlindClasses,
    possibleDataClasses,
    possibleDimmerClasses,
    possibleMotionClasses,
    possibleSocketClasses,
    possibleDoorCommunicationClasses,
]

const possibleTypes = possibleClasses.map((el, index) => {
    let ret = {
        types: Object.keys(el.types)
    }

    switch(index) {
        case 1:
            ret.class = 'blind'
            break;
        case 2:
            ret.class = 'data'
            break;
        case 3:
            ret.class = 'dimmer'
            break;
        case 4:
            ret.class = 'motion'
            break;
        case 5:
            ret.class = 'socket'
            break;
        case 6:
            ret.class = 'doorcommunication'
            break;
        default:
            ret.class = 'switch'
    }

    return ret
})

export const getClassForName = (name) => {
    let className = null
    let classIndex = 0
    while (className === null && classIndex < possibleTypes.length) {
        if (possibleTypes[classIndex]?.types?.find(id => id === name)) {
            className = possibleTypes[classIndex]?.class
        }
        classIndex++;
    }
    return className
}

export const getNameFromMachines = (feature, data, entities, deviceId, deviceList) => {
    let name = null
    let classIndex = 0

    while (name === null && classIndex < possibleClasses.length) {
        if (possibleClasses[classIndex]?.classes?.find(id => id === feature.Id)) {
            const keys = Object.keys(possibleClasses[classIndex].types)
            let keysIndex = 0
            while (name === null && keysIndex < keys.length) {
                const el = possibleClasses[classIndex].types[keys[keysIndex]]
                el.forEach(el2 => {
                    if ((Array.isArray(el2?.feature) && el2?.feature.find(feat => feat === feature.Id))
                        || (!Array.isArray(el2?.feature) && el2?.feature === feature.Id)
                    ) {
                        let possible = true
                        el2.filters.forEach(filter => {
                            possible = !!feature.Feature.find(feat => {
                                if (feat.Fname === filter.Id) {
                                    if (Array.isArray(filter.Value)) {
                                        return !!filter.Value.find(val => val == feat.Fvalue)
                                    } else {
                                        return feat.Fvalue == filter.Value
                                    } 
                                }
                                return false
                            })
                        });

                        if (possible) {
                            if(keys[keysIndex] === 'KOMFORTSCHALTER' || keys[keysIndex] === 'BEWEGUNGSMELDER') {
                                if (data?.komfortschalter) {
                                    name = 'KOMFORTSCHALTER'
                                } else {
                                    const device = entities[deviceId]
                                
                                    const availableDescriptions = [
                                        device.availableDeviceId,
                                            ...device.devices.map(id => {
                                                const dev = entities[id]
                                                return dev?.availableDeviceId
                                            }).filter(el => !!el)
                                        ].map(id => deviceList.find(el => el.getSupplierpid() === id))
                                        .filter(el => !!el)
                                        .map(el => el.getDescriptionshort())

                                    if (availableDescriptions.find(el => el.includes('Bedienelement flex'))) {
                                        name = "FLEXSCHALTER"
                                    } else if (availableDescriptions.find(el => el.includes('Komfortschalter'))) {
                                        name = 'KOMFORTSCHALTER'
                                    } else {
                                        name = "BEWEGUNGSMELDER"
                                    }
                                }
                            } else {
                                name = keys[keysIndex]
                            }
                        }
                    }
                })
                keysIndex++
            }

        }
        classIndex++
    }
    return name
}

export const getFeatureDescriptionFromOperationPoint = (state, combination) => {
    return createDraftSafeSelector(
        [getDeviceList, getSynonyms, getAdditionalData],
        (deviceList, synonyms, additionalData) => {
            let classes = []
            if (combination?.entities && combination.entities[combination.rootEntityId]) {
                combination.entities[combination.rootEntityId].devices.forEach((deviceId) => {
                    const device = combination.entities[deviceId]
                    if (deviceList && device?.availableDeviceId) {
                        if (device.availableDeviceId === 'Hirschmann' || device.availableDeviceId === 'Ankaro') {
                            let index = classes.findIndex(el => el.id === device.availableDeviceId)
                            if (index === -1) {
                                classes.push({
                                    id: device.availableDeviceId,
                                    description: synonyms.find(el => el.classID === "EC000439")?.synonym, //Antennensteckdose
                                    quantity: 1,
                                })
                            } else {
                                classes[index].quantity++
                            }
                        } else {
                            const deviceProto = deviceList.find(el => el.getSupplierpid() === device.availableDeviceId)
                            
                            if (deviceProto && synonyms && synonyms.length > 0) {
                                const features = JSON.parse(deviceProto.getFeatures())
                                const data = additionalData.find(el => el.supplier_pid ===  device.availableDeviceId)
                                if (features && features[0]) {
                                    const name = getNameFromMachines(features[0], data, combination.entities, deviceId, deviceList)
                                    classes.push({
                                        id: features[0].Id,
                                        description: name || synonyms.find(el => el.classID === features[0].Id)?.synonym,
                                        quantity: 1,
                                    })
                                }
                            }
                        }
                    }
                })
            }
            
            return classes
        }
    )(state)
}

export const getMergedFeatureDescriptionFromOperationPoint = (state, combination) => {
    return createDraftSafeSelector(
        [getDeviceList, getSynonyms],
        (deviceList, synonyms) => {
            const classes = getFeatureDescriptionFromOperationPoint(state, combination)
            return classes.reduce((acc, el) => {
                let index = acc.findIndex(el2 => el2.id === el.id)
                if (index === -1) {
                    acc.push(el)
                } else {
                    acc[index].quantity++
                }
                return acc
            }, [])
        }
    )(state)
}

export const getIsSocket = (state, availableDeviceId) => {
    return createDraftSafeSelector(
        [getDeviceList],
        (deviceList) => {
            if (deviceList && availableDeviceId) {
                const deviceProto = deviceList.find(el => el.getSupplierpid() === availableDeviceId)
                if (deviceProto) {
                    const features = JSON.parse(deviceProto.getFeatures())
                    if (features && features[0]) {
                        return features[0].Id === 'EC000125' //Steckdose
                    }
                }
            }
            return false
        }
    )(state)
}

export const getProjectResolved = createSelector(
    [getEditableProject, getAllEntities, getConfiguratedDevices, getDeviceList],
    (project, entities, allDevices, deviceList) => {
        /*const devices = allDevices
            .map(el => {
                let originDevice = deviceList.find(el2 => el2.getSupplierpid() === el.availableDeviceId)
                return {
                    ...el,
                    originDevice
                }
            })
            .filter(el => el.originDevice)
            .map(el => {
                return {
                    supplierPid: el.originDevice.getSupplierpid(),
                    manufacturertypedescr: el.originDevice.getManufacturertypedescr(),
                    descriptionShort: el.originDevice.getDescriptionshort(),
                    quantity: el.quantity,
                    price:
                        Math.round(
                            el.originDevice
                                ?.getPricedetails()
                                ?.getPricesList()[0]
                                ?.getAmount() * 100 || 0
                        ) / 100,
                    link: el.originDevice
                        ?.getMimedetails()
                        ?.getMimeInfosList()
                        ?.find(el => {
                            return el.getSource().includes('https://new.abb.com/products/')
                        })
                        ?.getSource(),
                }
            })

        Object.keys(entities).forEach(key => {
            entities[key] = {
                ...entities[key],
                originDevice: devices.find(el2 => el2.supplierPid === entities[key].availableDeviceId)
            }
        })*/

        return {
            ...project,
            ID: project.ID === 'local' ? -1 : project.ID,
            data: JSON.stringify({
                ...project.data,
                entities
            })
        }
    }
)

export const mapDataForPlansoftExort = createDraftSafeSelector(
    [getAllEntities],
    (allEntities) => {
        let rootEntityId = null
        let entities = JSON.parse(JSON.stringify(allEntities))
        Object.keys(entities).forEach(id => {
            let entity = entities[id]
            if (entity) {
                if (entity.type === 'building') {
                    rootEntityId = id
                } else if (entity.type === 'room') {
                    entity.devices = []
                    entity.operationPoints.forEach(operationPointId => {
                        let operationPoint = entities[operationPointId]
                        if (operationPoint.frame) {
                            entity.devices.push(operationPoint.frame)
                            entities[operationPoint.frame].type = 'device'
                            entities[operationPoint.frame].text = ''
                            delete entities[operationPoint.frame].devices
                            delete entities[operationPoint.frame].onPiecelist
                            delete entities[operationPoint.frame].position
                        }
                        operationPoint.devices.forEach(deviceId => {
                            entity.devices.push(deviceId)
                            let device = entities[deviceId]
                            device.devices.forEach(device2Id => {
                                entity.devices.push(device2Id)
                                entities[device2Id].type = 'device'
                                entities[device2Id].text = ''
                                entities[device2Id].quantity = operationPoint.quantity
                                delete entities[device2Id].devices
                                delete entities[device2Id].onPiecelist
                                delete entities[device2Id].position
                            })
                            entities[deviceId].type = 'device'
                            entities[deviceId].text = ''
                            entities[deviceId].quantity = operationPoint.quantity
                            delete entities[deviceId].devices
                            delete entities[deviceId].onPiecelist
                            delete entities[deviceId].position
                        })
                        delete entities[operationPointId]
                    })
                    delete entity.operationPoints
                }
                delete entities[id].config
            }
        })

        let date = new Date()
        return {
            date: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
            type: 'Wohnung',
            "projectType": 'room',
            rootEntityId,
            entities,
        }
    }
)

export const getParentDesignRange = (
    state,
    id,
    design = {
        serie: null,
        frameColor: null,
        cplateColor: null
    }
) => {
    return createDeepEqualSelector([getAllEntities, getDesignInformationFromProject], (entities, designInformation) => {
        let entity = id ? entities[id] : null
        
        if (!entity || entity.type === 'building') {
            if (!design.serie) {
                design.serie = designInformation?.frameRangeId
            }
    
            if (!design.frameColor) {
                design.frameColor = designInformation?.frameColorId
            }
    
            if (!design.cplateColor) {
                design.cplateColor = designInformation?.switchColorId
            }
        } else {
            if (!design.serie) {
                design.serie = entity?.config?.find(el => el.type === 'serie')?.value
            }
    
            if (!design.frameColor) {
                design.frameColor = entity?.config?.find(el => el.type === 'frameColor')?.value
            }
    
            if (!design.cplateColor) {
                design.cplateColor = entity?.config?.find(el => el.type === 'cplateColor')?.value
            }
        }
        

        if (entity?.parent && (!design.serie || !design.frameColor || !design.cplateColor)) {
            return getParentDesignRange(state, entity.parent, design)
        }
        return design
    })(state)
}

export const getIdsForDesignRangeChange = (
    state,
    id,
) => {
    return createDeepEqualSelector([getAllEntities], (entities) => {
        let ids = {
            building: null,
            floor: null,
            room: null,
            operationPoint: null
        }
        
        let entity = id ? entities[id] : null
        
        while(entity) {
            if (ids.hasOwnProperty(entity.type)) {
                ids[entity.type] = entity.id
            }
            entity = entity.parent ? entities[entity.parent] : null
        }
        return ids
    })(state)
}

export const getCplateForSerie = (state, seriesId, cPlateColorId, availableDeviceId, limit = false, additionalArticles, orientation) => {
    return createDeepEqualSelector([getAvailableSeries, getDeviceList, getCplateMapping], (series, deviceList, cPlateMapping) => {
        const serie = series.find(s => s.id === seriesId)
        const originDevice = deviceList.find(el => el.getSupplierpid() === serie.deviceId)
        
        if (originDevice) {
            let splits = originDevice.getManufacturertypedescr().split('-')
            let index = -1
            cPlateMapping.forEach(el => {
                if (el.serie === seriesId) {
                    const foundIndex = splits.findIndex(el2 => {
                        return el2 === el.articleColor
                    })
                    if (foundIndex > -1) {
                        index = foundIndex
                    }
                }
            })
            const newCPlateMapping = cPlateMapping.find(el => el.serie === seriesId && el.colorId === cPlateColorId)

            const newArticleName = splits.map((el, i) => {
                if (i === index && newCPlateMapping) {
                    return newCPlateMapping.articleColor
                }
                return el
            }).join('-')
            
            const newOriginDevice = deviceList.find(el => el.getManufacturertypedescr() === newArticleName)

            if (newOriginDevice) {
                return {
                    supplierPid: newOriginDevice.getSupplierpid()
                }
            }
        }
        return getCplate(state, seriesId, cPlateColorId, availableDeviceId, limit, additionalArticles, orientation)
    })(state)
}