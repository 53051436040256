import { Machine, assign } from 'xstate'

const initialValues = {
    type: 'TYPE_NONE',
    switch: 'SWITCH_NONE',
    operation: 'OPERATION_NONE',
    extension: 'EXTENSION_NONE',
    timing: 'TIMING_NONE',
    sensor: 'SENSOR_NONE',
    specials: 'SPECIALS_NONE',
}

export const calculate = (context, event, actionMeta) => {
    let value = JSON.parse(JSON.stringify(actionMeta.state?.value || initialValues))
    if (event?.payload?.identifier) {
        value[event.payload.identifier] = event.type
    }
    const { type, switch: sw, operation, extension, /*timing,*/ sensor, specials } = value
    let notPossible = false

    let formStatus = {}
    Object.keys(blindMachine.states).forEach(key => {
        formStatus[key] = {
            open: key === 'type' ? true : false
        }
    })

    context = [{
        feature: '',
        filters: [
            {
                Id: 'EF000005', //Zusammenstellung
                Value: 'EV000078', //Basiselement
            },
        ],
        additionalFilters: []
    }]

    if (type === 'MECHANISCH') {
        if (
            operation === 'MANUEL' ||
            operation === 'IR' ||
            specials === 'NEUTRALLEITER' ||
            specials === 'LAUFZEIT_PARAMETRIERBAR'
        ) {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC000044' //Jalousieschalter/-taster
        }
    } else if (type === 'ELEKTRONISCH') {
        context[0].feature = 'EC000615' //Jalousiesteuerung
    }

    if (sw === 'SCHALTER') {
        context[0].filters.push({
            Id: 'EF000010', //Ausführung
            //EV000499 Schalter 1-polig
            //EV000500 Schalter 2-polig
            //EV006509 Tastrastschalter 1-polig
            //EV006510 Tastrastschalter 2-polig
            Value: ['EV000499', 'EV000500', 'EV006509', 'EV006510'],
        })
    } else if (sw === 'TASTER') {
        context[0].filters.push({
            Id: 'EF000010', //Ausführung
            Value: ['EV000502', 'EV000503'], //EV000502 Taster 1-polig oder EV000503 Taster 2-polig
        })
    }

    if (operation === 'DRUECKEN') {
        context[0].filters.push({
            Id: 'EF000006', //Bedienungsart
            Value: 'EV000200', //Wippe
        })
    } else if (operation === 'SCHLUESSEL') {
        context[0].filters.push({
            Id: 'EF000006', //Bedienungsart
            Value: 'EV000175', //Schlüssel
        })
    } else if (operation === 'DREHEN') {
        context[0].filters.push({
            Id: 'EF000006', //Bedienungsart
            Value: 'EV000092', //Drehknopf
        })
    } else if (operation === 'IR') {
        context[0].additionalFilters.push('ir')
    }

    if (extension === 'NEBENSTELLE') {
        context[0].filters.push({
            Id: 'EF003876', //Nebenstelleneingang
            Value: 'true',
        })
    }

    if (sensor === 'HELLIGKEIT') {
        context[0].filters.push({
            Id: 'EF003872', //Mit Helligkeitssensoranschluss
            Value: 'true',
        })
    } else if (sensor === 'GLAS') {
        context[0].filters.push({
            Id: 'EF003871', //Mit Glasbruchmelderanschluss
            Value: 'true',
        })
    } else if (sensor === 'WIND') {
        context[0].filters.push({
            Id: 'EF005866', //Geeignet für Windsensor
            Value: 'true',
        })
    } else if (sensor === 'REGEN') {
        context[0].filters.push({
            Id: 'EF005847', //Geeignet für Regensensor
            Value: 'true',
        })
    }

    if (specials === 'NEUTRALLEITER') {
        context[0].additionalFilters.push('neutralleiter')
    } else if (specials === 'LAUFZEIT_PARAMETRIERBAR') {
        context[0].additionalFilters.push('Neutralleiter')
    }

    if (type === 'ELEKTRONISCH') {
        context.push({
            feature: ['EC001035', 'EC001588'], //EC001035 Bewegungsmelder Basiselement oder EC001588 Elektronischer Schalter
            filters: [{
                "Id": "EF000010", //Ausführung
                "Value": "EV004400" //Nebenstelle
            }],
            additionalFilters: []
        })
    }

    if (type === 'TYPE_NONE') {
        //Jalousieschalter/-taster, Jalousiesteuerung oder Jalousiesteuerung
        context[0].feature = ['EC000044', 'EC000615'] //EC000044 Jalousieschalter/-taster oder EC000615 Jalousiesteuerung
        context.push({
            feature: ['EC001035', 'EC001588'], //EC001035 Bewegungsmelder Basiselement oder EC001588 Elektronischer Schalter
            filters: [{
                "Id": "EF000010", //Ausführung
                "Value": "EV004400" //Nebenstelle
            }],
            additionalFilters: []
        })
    }

    return {
        descriptions: context,
        formStatus
    }
}

export const blindMachine = Machine(
    {
        id: 'blind',
        type: 'parallel',
        context: {},
        states: {
            type: {
                initial: initialValues.type,
                on: {
                    TYPE_NONE: 'type.TYPE_NONE',
                    MECHANISCH: 'type.MECHANISCH',
                    ELEKTRONISCH: 'type.ELEKTRONISCH',
                },
                states: {
                    TYPE_NONE: {
                        entry: ['calculate'],
                    },
                    MECHANISCH: {
                        entry: ['calculate'],
                    },
                    ELEKTRONISCH: {
                        entry: ['calculate'],
                    },
                },
            },
            switch: {
                initial: initialValues.switch,
                on: {
                    SWITCH_NONE: 'switch.SWITCH_NONE',
                    SCHALTER: 'switch.SCHALTER',
                    TASTER: 'switch.TASTER',
                },
                states: {
                    SWITCH_NONE: {
                        entry: ['calculate'],
                    },
                    SCHALTER: {
                        entry: ['calculate'],
                    },
                    TASTER: {
                        entry: ['calculate'],
                    },
                },
            },
            operation: {
                initial: initialValues.operation,
                on: {
                    OPERATION_NONE: 'operation.OPERATION_NONE',
                    DRUECKEN: 'operation.DRUECKEN',
                    SCHLUESSEL: 'operation.SCHLUESSEL',
                    MANUEL: 'operation.MANUEL',
                    DREHEN: 'operation.DREHEN',
                    IR: 'operation.IR',
                },
                states: {
                    OPERATION_NONE: {
                        entry: ['calculate'],
                    },
                    DRUECKEN: {
                        entry: ['calculate'],
                    },
                    SCHLUESSEL: {
                        entry: ['calculate'],
                    },
                    MANUEL: {
                        entry: ['calculate'],
                    },
                    DREHEN: {
                        entry: ['calculate'],
                    },
                    IR: {
                        entry: ['calculate'],
                    },
                },
            },
            extension: {
                initial: initialValues.extension,
                on: {
                    EXTENSION_NONE: 'extension.EXTENSION_NONE',
                    NEBENSTELLE: 'extension.NEBENSTELLE',
                },
                states: {
                    EXTENSION_NONE: {
                        entry: ['calculate'],
                    },
                    NEBENSTELLE: {
                        entry: ['calculate'],
                    },
                },
            },
            sensor: {
                initial: initialValues.sensor,
                on: {
                    SENSOR_NONE: 'sensor.SENSOR_NONE',
                    TEMPERATUR: 'sensor.TEMPERATUR',
                    HELLIGKEIT: 'sensor.HELLIGKEIT',
                    GLAS: 'sensor.GLAS',
                    WIND: 'sensor.WIND',
                    REGEN: 'sensor.REGEN',
                },
                states: {
                    SENSOR_NONE: {
                        entry: ['calculate'],
                    },
                    TEMPERATUR: {
                        entry: ['calculate'],
                    },
                    HELLIGKEIT: {
                        entry: ['calculate'],
                    },
                    GLAS: {
                        entry: ['calculate'],
                    },
                    WIND: {
                        entry: ['calculate'],
                    },
                    REGEN: {
                        entry: ['calculate'],
                    },
                },
            },
            specials: {
                initial: initialValues.specials,
                on: {
                    SPECIALS_NONE: 'specials.SPECIALS_NONE',
                    NEUTRALLEITER: 'specials.NEUTRALLEITER',
                    LAUFZEIT_PARAMETRIERBAR: 'specials.LAUFZEIT_PARAMETRIERBAR',
                },
                states: {
                    SPECIALS_NONE: {
                        entry: ['calculate'],
                    },
                    NEUTRALLEITER: {
                        entry: ['calculate'],
                    },
                    LAUFZEIT_PARAMETRIERBAR: {
                        entry: ['calculate'],
                    },
                },
            },
        },
    },
    {
        actions: {
            calculate: assign(calculate),
        },
    }
)

export const possibleBlindClasses = {
    //EC000044 Jalousieschalter/-taster
    //EC000615 Jalousiesteuerung
    //EC001035 Bewegungsmelder Basiselement
    //EC001588 Elektronischer Schalter
    classes: ['EC000044', 'EC000615', 'EC001035', 'EC001588'],
    types: {
        'MECHANISCH': blindMachine.transition(blindMachine.initialState, { type: 'MECHANISCH', payload: { identifier: 'type'} })?.context?.descriptions,
        'ELEKTRONISCH': blindMachine.transition(blindMachine.initialState, { type: 'ELEKTRONISCH', payload: { identifier: 'type'} })?.context?.descriptions
    }
}

export default blindMachine
