import v4 from 'node-uuid'
import omit from 'lodash/omit'
import { batchActions } from 'redux-batched-actions'
import { getEntityResolved } from '../duck'
import { generateDeleteSequence } from '../helpers/index'
import { referenceDeviceWithOperationPoint, referenceFrameWithOperationPoint } from '../operationPoint/duck'

// Actions
//
export const ADD_DEVICE = 'raumplaner/entities/DEVICE/ADD'
export const CLONE_DEVICE = 'raumplaner/entities/DEVICE/CLONE'
export const REMOVE_DEVICE = 'raumplaner/entities/DEVICE/REMOVE'
export const CHANGE_DEVICE = 'raumplaner/entities/DEVICE/CHANGE'
export const CHANGE_QUANTITY = 'raumplaner/entities/DEVICE/QUANTITY'
export const CHANGE_ON_PIECELIST = 'raumplaner/entities/DEVICE/PIECELIST'

// Reducer
//
export const initialState = {
    type: 'device',
    id: null,
    availableDeviceId: null,
    quantity: 0,
    devices: [],
    onPiecelist: true,
    position: 'full'
}

function deviceReducer(state = initialState, action) {
    switch (action.type) {
        case CLONE_DEVICE:
            return {
                ...state,
                id: action.deviceId,
                devices: [],
                parent: action.parentId,
            }
        case ADD_DEVICE:
            return {
                ...state,
                id: action.deviceId,
                parent: action.parentEntityId,
                availableDeviceId: action.availableDeviceId,
                quantity: 1,
                type: action.deviceType,
                onPiecelist: action.onPiecelist,
                position: action.position
            }
        case CHANGE_QUANTITY:
            return {
                ...state,
                quantity: action.quantity,
            }
        case CHANGE_DEVICE:
            return {
                ...state,
                availableDeviceId: action.availableDeviceId,
            }
        case CHANGE_ON_PIECELIST:
            return {
                ...state,
                onPiecelist: action.onPiecelist,
            }
        default:
            return state
    }
}

export default function deviceListReducer(
    state = { byId: {}, allIds: [] },
    action
) {
    let allIdIndex
    switch (action.type) {
        case CLONE_DEVICE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.deviceId]: deviceReducer(
                        state.byId[action.originDeviceId],
                        action
                    ),
                },
                allIds: [...state.allIds, action.deviceId],
            }
        }
        case ADD_DEVICE: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.deviceId]: deviceReducer(
                        state.byId[action.deviceId],
                        action
                    ),
                },
                allIds: [...state.allIds, action.deviceId],
            }
        }
        case REMOVE_DEVICE: {
            allIdIndex = state.allIds.indexOf(action.deviceId)
            return {
                byId: omit(state.byId, [action.deviceId]),
                allIds: [
                    ...state.allIds.slice(0, allIdIndex),
                    ...state.allIds.slice(allIdIndex + 1),
                ],
            }
        }
        case CHANGE_ON_PIECELIST:
        case CHANGE_QUANTITY:
        case CHANGE_DEVICE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: deviceReducer(state.byId[action.id], action),
                },
            }
        default: {
            return state
        }
    }
}

// Action Creators
//
export const addDevice = (
    availableDeviceId,
    parentEntityId,
    deviceId = v4(),
    deviceType = 'device',
    onPiecelist = true,
    position = initialState.position
) => {
    return {
        type: ADD_DEVICE,
        availableDeviceId,
        parentEntityId,
        deviceId,
        deviceType,
        onPiecelist,
        position
    }
}

export const addDeviceToOperationPoint = (
    availableDeviceId,
    opId,
    deviceId = v4(),
    deviceType = 'device',
    onPiecelist = true,
    position = initialState.position
) => {
    return batchActions([
        addDevice(availableDeviceId, opId, deviceId, deviceType, onPiecelist, position),
        referenceDeviceWithOperationPoint(opId, deviceId),
    ])
}

export const addFrameToOpertaionPoint = (availableDeviceId, opId, deviceId = v4(), onPiecelist = true) => {
    return batchActions([
        addDevice(availableDeviceId, opId, deviceId, 'frame', onPiecelist, initialState.position),
        referenceFrameWithOperationPoint(opId, deviceId),
    ])
}

export const deleteDevice = deviceId => {
    return {
        type: REMOVE_DEVICE,
        deviceId,
    }
}

export const removeDevice = device => {
    return (dispatch, getState) => {
        if (!device.hasOwnProperty('id')) {
            device = getEntityResolved(getState(), device)
        }
        if (!device.parent.hasOwnProperty('id')) {
            device = getEntityResolved(getState(), device.id)
        }
        return dispatch(generateDeleteSequence(getState(), device, 0))
    }
}

export const copyDevice = (parentId, originDeviceId, deviceId = v4()) => {
    return {
        type: CLONE_DEVICE,
        parentId,
        originDeviceId,
        deviceId,
    }
}
export const cloneRoomDevice = (parentId, originDeviceId, deviceId = v4()) => {
    return batchActions([
        copyDevice(parentId, originDeviceId, deviceId),
        referenceDeviceWithOperationPoint(parentId, deviceId),
    ])
}

export const changeQuantity = (deviceId, quantity) => {
    return {
        type: CHANGE_QUANTITY,
        id: deviceId,
        quantity: parseInt(quantity),
    }
}

export const changeDevice = (deviceId, availableDeviceId) => {
    return {
        type: CHANGE_DEVICE,
        id: deviceId,
        availableDeviceId,
    }
}

export const changeOnPiecelist = (deviceId, onPiecelist) => {
    return {
        type: CHANGE_ON_PIECELIST,
        id: deviceId,
        onPiecelist: onPiecelist,
    }
}

export const noDevice = [
    'Ankaro',
    'Hirschmann'
]