import { batchActions } from 'redux-batched-actions'

import { changeOnPiecelist} from '../device/duck'
import { getAllOperationPoints, getIsSocket } from '../duck'

export function updateOnPiecelist(dispatch, getState, onPiecelist) {
    let actions = []

    const state = getState()

    const operationPoints = getAllOperationPoints(state)
    operationPoints.forEach(operationPoint => {
        if (operationPoint.devices) {
            operationPoint.devices.forEach(device => {
                if (device) {
                    if (!getIsSocket(state, device.availableDeviceId)) {
                        actions.push(changeOnPiecelist(device.id, !!onPiecelist))
                    }
                }
            })
        }
    })

    dispatch(batchActions(actions))
}
