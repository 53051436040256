import React, {lazy, Suspense} from 'react'
import { HashRouter as Router } from 'react-router-dom'

import { ThemeProvider } from '@qmarketing/react-component-library'

import {IntlProvider} from 'react-intl';

import { gsap, CSSPlugin } from 'gsap/all'

import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClientProvider } from 'react-query'

//Redux
import configureStore from './redux/store/configureStore'
import { Provider } from 'react-redux';

//Toastify
import { ToastContainer } from 'react-toastify';

import { PersistGate } from 'redux-persist/integration/react';

import CircularProgress from '@material-ui/core/CircularProgress'

import './assets/css/slider/slick.css'
import './assets/css/slider/slick-theme.css'
import {queryClient} from './vendor/queryClient'
import messages_de from './assets/translations/de-DE.json';

const Layout = lazy(() => import('./components/layout'))

const messages = {
    de: messages_de,
}
const language = 'de'

const formats = {
    number: {
        de: {
            style: 'currency',
            currency: 'EUR'
        }
    }
};

gsap.registerPlugin(CSSPlugin)

export default function App() {
    const {store, persistor} = configureStore({})
    return (
        <div className="app">
            <ToastContainer
                toastClassName='toast-container'
                autoClose={3000}
            />
            <Provider store={store} >
                <Router>
                    <ThemeProvider>
                        <IntlProvider
                            locale={language}
                            messages={messages[language]}
                            formats={formats}
                            defaultFormats={formats}
                            onError={(err) => {
                                if (err.code === "MISSING_TRANSLATION") {
                                    console.warn("Missing translation", err.message);
                                    return;
                                }
                                throw err;
                            }}
                        >
                            <QueryClientProvider client={queryClient}>
                                <PersistGate loading={null} persistor={persistor}>
                                    <Suspense fallback={() => <CircularProgress color="primary" />}>
                                        <Layout/>
                                    </Suspense>
                                    <ReactQueryDevtools initialIsOpen={false} />
                                </PersistGate>
                            </QueryClientProvider>
                        </IntlProvider>
                    </ThemeProvider>
                </Router>
            </Provider>
        </div>
    )
}
