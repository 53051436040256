import { calculatePossibleDevice } from "./calculatePossibleDevice"

export const getNoDevice = () => {
    return [
        'Ankaro',
        'Hirschmann'
    ]
}

export const getFeatureClass = (features, possibleForAddionalArticles) => {
    const noDevice = getNoDevice()
    return possibleForAddionalArticles
        ? features.find(feature => {
            return feature.Id.includes('EC') || noDevice.find(id => id === feature.Id)
        })
    : null
}

export const checkPossibleForAddionalArticles = (additionalArticles) => {
    const notPossibleAdditionalArticles = [
        '2CKA006800A2888',
        '2CKA006800A3041',
        '2CKA006800A3047',
        '2CKA006800A3044',
        '2CKA006500A0012',
        '2CKA006800A3050'
    ]
    return !notPossibleAdditionalArticles.find(id =>  {
        return !!additionalArticles.find(el => el.getSupplierpid() === id)
    })
}

export const getAdditionalArticles = (additionalArticles, deviceList) => {
    let data = additionalArticles ? [...additionalArticles] : []
    
    return data.map(id => {
        return deviceList.find(el => el.getManufacturertypedescr() === id)
    })
}

export const calculateDescritpions1 = (supplierPid, articleColor, additionalArticles, additionalData, serieId, features, possibleForAddionalArticles) => {

    let descriptions = []
    let description = {
        feature: 'EC000011', //Abdeckung/Bedienelement für Installationsschalterprogramme
        filters: [],
    }

    if (articleColor) {
        description.manufacturerTypeDescr = "-" + articleColor
    }

    const additionalArticleData = additionalData.find(el => el.supplier_pid === supplierPid)

    const featureClass = getFeatureClass(features, possibleForAddionalArticles)
    let description2 = null
    let description3 = null
    let description4 = null
    let bedienungsart = null
    let anzahlWippen = null
    if (featureClass) {
        switch (featureClass.Id) {
            case "Ankaro":
                //Einsatz/Abdeckung für Kommunikationstechnik
                description.feature = "EC000018"
                //Verwendung = Sat
                description.filters.push({Id: "EF000133", Value: "EV003629"})
                break;
            case "Hirschmann":
                //Einsatz/Abdeckung für Kommunikationstechnik
                description.feature = "EC000018"
                //Verwendung = Sat
                description.filters.push({Id: "EF000133", Value: "EV003629"})
                break;
            //mechanische Jalousiesteuerung
            case "EC000044":
                //Verwendungszweck Jalousie
                description.filters.push({Id: "EF000133", Value: "EV003624"})    
                bedienungsart = featureClass.Feature.find(el => el.Fname === "EF000006")?.Fvalue
                if (bedienungsart === "EV000200") {
                //Bedienunsart zweitelige Wippe
                    description.filters.push({Id: "EF000010", Value: "EV006540"})
                } else if (bedienungsart === "EV000092") {
                    //Bedienunsart Drehknopf
                    description.filters.push({Id: "EF000010", Value: "EV000092"})
                } else if (bedienungsart === "EV000175") {
                    //Bedienunsart Schlüssel
                    description.filters.push({Id: "EF000010", Value: "EV000175"})
                }
                break
            //elektronische Jalousiesteuerung
            case "EC000615":
                    //Verwendungszweck Jalousie
                    description.filters.push({Id: "EF000133", Value: "EV003624"})
                    //Bedienunsart einteilige Wippe
                    description.filters.push({Id: "EF000010", Value: "EV000316"})
                break
            //Kommunikation TAE
            case "EC001264":
                description.feature = "EC000018"
                description2 = JSON.parse(JSON.stringify(description))                        
                //Verwendungszweck TAE
                description.filters.push({Id: "EF000133", Value: "EV000378"})
                //Verwendungszweck D-Sub (keine Ahnung warum diese dann dort gelistet werden)
                description2.filters.push({Id: "EF000133", Value: "EV000734"})
                descriptions.push(description2)
                break
            //Kommunikation (UAE, TAE, Speaker)
                case "EC000018":
                description.feature = "EC000018"
                if (featureClass.Feature.find(f => f.Fname === 'EF000133' && f.Fvalue === 'EV000154')) { //Sonstige Geräte
                    if (additionalArticleData?.uae) {
                        //Verwendungszweck UAE/IAE
                        description.filters.push({Id: "EF000133", Value: "EV004378"})
                        //Für Art Linear Geräte
                        description2 = {
                            //Abdeckung/Bedienelement für Installationsschalterprogrammei
                            feature: "EC000018",
                            filters: [
                                {
                                    Id: "EF000133", //Verwendungszweck
                                    Value: "EV006388" //Datenanschlussgehäuse
                                },
                                {
                                    Id: "EF000005", //Zusammenstellung
                                    Value:"EV006351" //Bedienelement
                                },
                                {
                                    Id: "EF005709", //mit Tragring
                                    Value: "false"
                                }
                            ]
                        }
                        if (articleColor) {
                            description2.manufacturerTypeDescr = "-" + articleColor
                        }
                        
                        
                        //Überprüfung der Größe 1 oder 2-fach
                        const size = featureClass.Feature.find(f => f.Fname === 'EF001579')
                        if (size) {
                            description.filters.push({Id: "EF001579", Value: size.Fvalue})
                            description2.filters.push({Id: "EF001579", Value: size.Fvalue})
                        }
                        descriptions.push(description2)
                    } else if (additionalArticleData?.speaker) {
                        description2 = JSON.parse(JSON.stringify(description))                        
                        //Verwendungszweck TAE
                        description.filters.push({Id: "EF000133", Value: "EV000378"})
                        //Verwendungszweck D-Sub (keine Ahnung warum diese dann dort gelistet werden)
                        description2.filters.push({Id: "EF000133", Value: "EV000734"})
                        descriptions.push(description2)
                    }
                } else if (featureClass.Feature.find(f => f.Fname === 'EF000133' && f.Fvalue === 'EV000378')) { //TAE Geräte
                    description2 = JSON.parse(JSON.stringify(description))                        
                    //Verwendungszweck TAE
                    description.filters.push({Id: "EF000133", Value: "EV000378"})
                    //Verwendungszweck D-Sub (keine Ahnung warum diese dann dort gelistet werden)
                    description2.filters.push({Id: "EF000133", Value: "EV000734"})
                    descriptions.push(description2)
                }
                break
            //Kommunikation (Antenna)
            case "EC000439":
                description.feature = "EC000018"
                description2 = JSON.parse(JSON.stringify(description))
                description3 = JSON.parse(JSON.stringify(description))
                description4 = JSON.parse(JSON.stringify(description))
                //Verwendungszweck Sat
                description.filters.push({Id: "EF000133", Value: "EV003629"})
                description2.filters.push({Id: "EF000133", Value: "EV003629"})
                //Verwendungszweck Sonstige Geräte
                description3.filters.push({Id: "EF000133", Value: "EV000154"})
                description4.filters.push({Id: "EF000133", Value: "EV000154"})
                //ohne Tragring
                description3.filters.push({Id: "EF005709", Value: "false"})
                description4.filters.push({Id: "EF005709", Value: "false"})

                //Anzahl Buchsen 2 oder 3 möglich
                description.filters.push({Id: "EF001579", Value:"2"})
                description2.filters.push({Id: "EF001579", Value:"3"})
                description3.filters.push({Id: "EF001579", Value:"2"})
                description4.filters.push({Id: "EF001579", Value:"3"})
                descriptions.push(description2)
                descriptions.push(description3)
                descriptions.push(description4)
                break
            //Raumtemperaturregler¼
            case "EC010927":
                const bedienung = featureClass.Feature.find(f => f.Fname === "EF002147")?.Fvalue
                if (bedienung === "EV008828") { //Dreh
                    description2 = JSON.parse(JSON.stringify(description))
                    description3 = JSON.parse(JSON.stringify(description))
                    //Verwendungszweck Thermostat
                    description.filters.push({Id: "EF000133", Value: "EV000615"})
                    description2.filters.push({Id: "EF000133", Value: "EV000615"})
                    //Verwendungszweck sonstige Geräte
                    description2.filters.push({Id: "EF000133", Value: "EV000615"})
                    //Ausführung Dreh
                    description.filters.push({Id: "EF000010", Value: "EV000092"})
                    //Ausführung nicht gesetzt
                    description2.filters.push({Id: "EF000010", Value: "-"})
                    //Auufdruck Symbol "1"
                    description2.filters.push({Id: "EF003823", Value: "EV006497"})
                    //Auufdruck diverse Symbole
                    description3.filters.push({Id: "EF003823", Value: "EV001917"})
                    descriptions.push(description2)
                    descriptions.push(description3)
                } else if (bedienung === "EV006506") { //Taster
                    //Verwendungszweck Thermostat
                    description.filters.push({Id: "EF000133", Value: "EV000615"})
                    //Ausführung nicht gesetzt
                    description.filters.push({Id: "EF000010", Value: "-"})
                    //Aufdruck diverse Symbole
                    description.filters.push({Id: "EF003823", Value: "EV001917"})
                } else if (bedienung === "-") { //nicht gesetzt
                    //Verwendungszweck Zeitschalter
                    description.filters.push({Id: "EF000133", Value: "EV001731"})
                    description2 = JSON.parse(JSON.stringify(description))
                    description3 = JSON.parse(JSON.stringify(description))
                    //Ausführung Zentralplatte
                    description.filters.push({Id: "EF000010", Value: "EV000319"})
                    //Ausführung nicht gesetzt
                    description2.filters.push({Id: "EF000010", Value: "-"})
                    //Ausführung nicht gesetzt
                    description3.filters.push({Id: "EF000010", Value: "EV000317"})
                    descriptions.push(description2)
                    descriptions.push(description3)
                }
                break
            //Raumtemperaturregler 2
            case "EC011309":
                //Verwendungszweck Zeitschalter
                description.filters.push({Id: "EF000133", Value: "EV001731"})
                description2 = JSON.parse(JSON.stringify(description))
                description3 = JSON.parse(JSON.stringify(description))
                //Ausführung Zentralplatte
                description.filters.push({Id: "EF000010", Value: "EV000319"})
                //Ausführung nicht gesetzt
                description2.filters.push({Id: "EF000010", Value: "-"})
                //Ausführung nicht gesetzt
                description3.filters.push({Id: "EF000010", Value: "EV000317"})
                descriptions.push(description2)
                descriptions.push(description3)
                break          
            //Dimmer
            case "EC000025":
                bedienungsart = featureClass.Feature.find(f => f.Fname === "EF000006")?.Fvalue
                if (bedienungsart === "EV000251") {
                    //Verwendung Dimmer
                    description.filters.push({Id: "EF000133", Value: "EV000944"})
                    //Ausführung Drehknopf
                    description.filters.push({Id: "EF000010", Value: "EV000092"})
                } else if (bedienungsart === "EV000554") {
                    //Bewegungsmelder
                    description.feature = "EC000138"
                    description.filters.push({Id: "EF000010", Value: "EV004048"})
                    description.filters.push({Id: "EF000003", Value: "EV000383"})
                } else if (bedienungsart === "EV008969") {
                    //Verwendung Dimmer
                    description.filters.push({Id: "EF000133", Value: "EV000944"})
                    description2 = JSON.parse(JSON.stringify(description))
                    //Ausführung einteilige Taste
                    description.filters.push({Id: "EF000010", Value: "EV000317"})
                    //Ausführung zweiteilige Taste
                    description.filters.push({Id: "EF000010", Value: "EV007981"})
                    descriptions.push(description2)
                }
                break
            //Industrieschalter
            case "EC001590":
                anzahlWippen = featureClass.Feature.find(f => f.Fname === "EF003816")?.Fvalue
                if (anzahlWippen === "2") {
                    //Ausführung zweiteilige Wippe
                    description.filters.push({Id: "EF000010", Value: "EV006540"})
                } else {
                    //Ausführung einteilige Wippe
                    description.filters.push({Id: "EF000010", Value: "EV000316"})
                }
                break;
            //Dreistufen-Schalter
            case "EC001036":
                    //Ausführung Drehknopf
                    description.filters.push({Id: "EF000010", Value: "EV000092"})
                    //Verwendung Stufenschalter
                    description.filters.push({Id: "EF000133", Value: "EV006495"})
                    //Symbol "0"
                    description.filters.push({Id: "EF003823", Value: "EV000556"})
                    break;
            //Taster
            case "EC000029":
                    bedienungsart = featureClass.Feature.find(el => el.Fname === "EF000006")?.Fvalue
                    if (bedienungsart === "EV000407") {
                        anzahlWippen = featureClass.Feature.find(f => f.Fname === "EF003816")?.Fvalue
                        if (anzahlWippen === "2") {
                            //Ausführung zweiteilige Wippe
                            description.filters.push({Id: "EF000010", Value: "EV006540"})
                        } else {
                            //Ausführung einteilige Wippe
                            description.filters.push({Id: "EF000010", Value: "EV000316"})
                        }
                    } else if (bedienungsart === "EV000239") {
                       //keinw Filterung siehe spezielle Filterung für 2020/01 US-101 und 2020/10 US-101
                    }
                    break;
            default:
                break
        }

        if (additionalArticles.length > 0) {
            additionalArticles.forEach(el => {
                if (el) {
                    const additionalDeviceFeatures =  JSON.parse(el.getFeatures())
                    //Bedienelement intelligent oder Abdeckung/Bedienelement für Installationsschalterprogramme (OneTouch)
                    if (additionalDeviceFeatures[0].Id === 'EC000205' || additionalDeviceFeatures[0].Id === 'EC000011') {
                        //Bussystem-Tastwippe
                        description.feature = "EC002253"
                        description.filters = []
                        //Bussystem KNX = false
                        description.filters.push({Id: "EF001499", Value: "false"})
                        //Funk bidirektional = true
                        description.filters.push({Id: "EF009466", Value: "true"})
                    } else if (additionalDeviceFeatures[0].Id === 'EC001588') {
                        description.feature = null
                        description.filters = []
                    }
                }
            })
        }

        if (description.feature) {
            descriptions.push(description)
        }
    }
    return {description, descriptions}
}

export const calculateDescritpions2 = (supplierPid, features, possibleForAddionalArticles, deviceList, description, descriptions, additionalData, serieId, additionalArticles, articleColor) => {
    const originDevice = deviceList.find(el => el.getSupplierpid() === supplierPid)
    
    const featureClass = possibleForAddionalArticles
        ? features.find(feature => {
            return feature.Id.includes('EC') || noDevice.find(id => id === feature.Id)
        })
        : null
    
    const noDevice = getNoDevice()

    const additionalArticleData = additionalData.find(el => el.supplier_pid === supplierPid)

    let possibleDevices = []

    if (featureClass) {
        //Keine Steckdosen (EC000125) oder Innenstation für Türkommunikation (EC000779)
        //da diese keine Zentralplatten benötigenz
        if (featureClass.Id !== "EC000125" && featureClass.Id !== "EC000779" ) {
            let devices = descriptions.length > 0
                ? calculatePossibleDevice(deviceList, descriptions, additionalData, serieId)
                : []
            possibleDevices = devices.map(device => {
                if (!device) {
                    return null
                }
                device.possible = {}
                descriptions.forEach((desc, index) => {
                    device.possible[index] = true
                    if (device !== null) {
                        device.IsDefault = true
                        const features = JSON.parse(device.features)
                        const featClass = features.find(feature => {
                            return feature.Id.includes('EC')
                        })
                        
                        //Spezielle Kalkulation for Raumtemperaturregler
                        if (featureClass.Id === "EC010927") {
                            if (originDevice) {
                                if (originDevice.getManufacturertypedescr().includes('UHKEA')) {
                                    if (!device.manufacturerTypeDescr.includes('HKEA-')) {
                                        device.possible[index] = false
                                    }
                                } else if (originDevice.getManufacturertypedescr().includes('UHK')
                                    && !originDevice.getManufacturertypedescr().includes('UHKTA-BT')
                                    && !originDevice.getManufacturertypedescr().includes('UHK-101')
                                ) {
                                    if (!device.manufacturerTypeDescr.includes('HK-')) {
                                        device.possible[index] = false
                                    }
                                } else if (originDevice.getManufacturertypedescr().includes('UTA')
                                    && !originDevice.getManufacturertypedescr().includes('UTA-BT')
                                ) {
                                    if (!device.manufacturerTypeDescr.includes('TA-')) {
                                        device.possible[index] = false
                                    }
                                } else {
                                    if (device.manufacturerTypeDescr.includes('HKEA-')
                                        || device.manufacturerTypeDescr.includes('HK-')
                                        || device.manufacturerTypeDescr.includes('TA-')
                                    ) {
                                        device.possible[index] = false
                                    }
                                }

                                if (device) {
                                    if (originDevice.getManufacturertypedescr() === '1094 U'
                                        || originDevice.getManufacturertypedescr() === '1097 U'
                                    ) {
                                        if (!device.manufacturerTypeDescr.includes('1794-')) {
                                            device.possible[index] = false
                                        }
                                    } else if (originDevice.getManufacturertypedescr() === '1094 U-101'
                                        || originDevice.getManufacturertypedescr() === '1097 U-101'
                                        || originDevice.getManufacturertypedescr() === '1099 UHK-101'
                                    ) {
                                        if (!device.manufacturerTypeDescr.includes('1794 R-')) {
                                            device.possible[index] = false
                                        }
                                    }


                                    if ((originDevice.getManufacturertypedescr().includes('1094')
                                        || originDevice.getManufacturertypedescr().includes('1097'))
                                        && !originDevice.getManufacturertypedescr().includes('-BT')
                                    ) {
                                        if (!device.manufacturerTypeDescr.includes('1794')) {
                                            device.possible[index] = false
                                        }
                                    } else if ((originDevice.getManufacturertypedescr().includes('1095')
                                        || originDevice.getManufacturertypedescr().includes('1096')
                                        || originDevice.getManufacturertypedescr().includes('1099'))
                                        && !originDevice.getManufacturertypedescr().includes('-BT')
                                        && !originDevice.getManufacturertypedescr().includes('1099 UHK-101')
                                    ) {
                                        if (!device.manufacturerTypeDescr.includes('1795')) {
                                            device.possible[index] = false
                                        }
                                    }
                                }
                            } else {
                                device.possible[index] = false
                            }
                        }

                        //Spezielle Kalkalation für Dimmer
                        if (featureClass.Id === "EC000025") {
                            if (device.manufacturerTypeDescr.includes('-510')) {
                                device.possible[index] = false
                            }

                            if (originDevice.getManufacturertypedescr() === '6526 U') {
                                if (!device.manufacturerTypeDescr.includes('6543-')) {
                                    device.possible[index] = false
                                }
                            }

                            if (originDevice.getManufacturertypedescr() === '6565 Ur') {
                                if (!device.manufacturerTypeDescr.includes('6545-')) {
                                    device.possible[index] = false
                                }
                            }

                            if (!originDevice.getManufacturertypedescr().includes('J-')) {
                                if (device.manufacturerTypeDescr.includes('J-')) {
                                    device.possible[index] = false
                                }
                            }

                        }

                        //Spezielle Kalkutlation mechanische Jalousiesteuerung
                        if (featureClass.Id === "EC000044") {
                            if (device.manufacturerTypeDescr.includes('JA/')) {
                                device.possible[index] = false
                            }
                        }

                        //Spezielle Kalkutlation Taster
                        if (featureClass.Id === "EC000029") {
                            if (originDevice.getManufacturertypedescr() === '2020/01 US-101'
                                ||  originDevice.getManufacturertypedescr() === '2020/10 US-101'
                            ) {
                                if (!device.manufacturerTypeDescr.includes('2520-')
                                    && !device.manufacturerTypeDescr.includes('1720-')
                                ) {
                                    device.possible[index] = false
                                }
                            }
                        }
                        
                        if (device && featClass.Id === desc.feature) {
                            desc.filters.forEach(filter => {
                                featClass.Feature.forEach(feature => {
                                    let withoutIcon = true
                                    //Aufdruck/Kennzeichnung
                                    if (filter.Id === "EF003823") {
                                        withoutIcon = false
                                    }

                                    if (filter.Id === feature.Fname) {
                                        if (filter.Value !== feature.Fvalue) {
                                            device.possible[index] = false
                                        }
                                    }
                                    
                                    if (withoutIcon) {
                                        //Aufdruck/Kennzeichnung
                                        if (feature.Fname === "EF003823") {
                                            //ungleich ohne (EV000494) oder ohne Aufdruck (EV005886)
                                            if (feature.Fvalue !== "EV000494"
                                            && feature.Fvalue !== "EV005886") {
                                                device.IsDefault = false
                                            }
                                        }
                                    }

                                    //Mit Beschriftungsfeld
                                    if (feature.Fname === "EF005040" && feature.Fvalue === "true") {
                                        device.IsDefault = false
                                    }
                                })
                            })
                        } else {
                            device.possible[index] = false
                        }
                    }
                })
                return device
            }).filter(device => {
                return Object.keys(device.possible).some(key => {
                    return device.possible[key] === true
                })
            })

            //Spezielle Kalkulation für Dimmer 2
            if (featureClass.Id === "EC000025") {
                const featClass = features.find(feature => {
                    return feature.Id.includes('EC')
                })

                featClass.Feature.forEach(feature => {
                    //Verwendbar mit Zeitschalter/Timer
                    if (feature.Fname === "EF003911" && feature.Fvalue === "true") {
                        featureClass.Feature.forEach(feature2 => {
                            //Kein Seriendimmer
                            if (feature2.Fname === "EF003889" && feature2.Fvalue === "false") {
                                //Lastart == universal
                                if (feature2.Fname === "EF001508" && feature2.Fvalue === "EV000028") {
                                    description = {
                                        feature: 'EC000615', //Jalousiesteuerung
                                        manufacturerTypeDescr: "-" + articleColor,
                                        filters: [
                                            {Id: "EF000005", Value: "EV006350"}, //Zusammenstellung = Bedienaufsatz
                                        ],
                                    }
                                    descriptions.push(description)
                                    possibleDevices = descriptions.length > 0
                                        ? calculatePossibleDevice(deviceList, descriptions)
                                        : []
                                }
                            }
                        })
                    }
                })   
            }
        }
        //Spezielle Kalkulation für Jalousiesteuerung, Elektronischer Schalter und Dimmer
        //in Verbindung mit Flex und den zusätzlichen Geräten, da sich die Anzahl der Wippen
        //anhand der zusätzlichen Artikel ergibt
        if (featureClass.Id === "EC000615" || featureClass.Id === "EC001588" || featureClass.Id === "EC000025") {
            if (additionalArticles.length > 0) {
                additionalArticles.forEach(el => {
                    if (el) {
                        const additionalDeviceFeatures =  JSON.parse(el.getFeatures())
                        //Zusätzliches Gerät vom Typ Bedienelement intelligent oder Abdeckung/Bedienelement für Installationsschalterprogramme
                        if (additionalDeviceFeatures[0].Id === 'EC000205' || additionalDeviceFeatures[0].Id === 'EC000011') {
                            //Ermittlung der Anzahl der Tasten
                            let countButton = additionalDeviceFeatures[0].Feature.find(el => el.Fname === 'EF003144')
                            if (!countButton) {
                                //Wenn dieser Wert nicht gesetzt prüfen ob die Ausführung eine zweiteilige Wippe
                                //sonst handelt es sich um eine einteilige Wippe
                                let ausfuehrung = additionalDeviceFeatures[0].Feature.find(el => el.Fname === 'EF000010')
                                
                                if (ausfuehrung) {
                                    countButton = ausfuehrung.Fvalue === 'EV000317'
                                        ? {Fvalue: "1"}
                                        : ausfuehrung.Fvalue === 'EV007981'
                                            ? {Fvalue: "2"}
                                            : null
                                }
                            }

                            if (countButton) {
                                possibleDevices = possibleDevices.filter(el => {
                                    const additionalDeviceData = additionalData.find(el2 => el2.supplier_pid === el.supplierPid)
                                    if (!additionalDeviceData) {
                                        return true
                                    }
                                    
                                    if (additionalDeviceData?.feature?.find(el => {
                                        return el.fname === 'excludeFromFlex' && el.fvalue === 'true'
                                    })) {
                                        return false
                                    }
                                    
                                    if (countButton.Fvalue === '1') {
                                        return additionalDeviceData.orientation === 'full'
                                    }

                                    if (countButton.Fvalue === '2') {
                                        return additionalDeviceData.orientation === 'left'
                                            || additionalDeviceData.orientation === 'right'
                                            || additionalDeviceData.orientation === 'both'
                                    }

                                    return true
                                })
                            }
                        }
                    }
                })
            }
        }

        if (noDevice.find(id => id === featureClass.Id)) {
            possibleDevices = possibleDevices.filter(device => {
                const originDevice = deviceList.find(el => el.getSupplierpid() === device.supplierPid)
                if (originDevice) {
                    return originDevice.getDescriptionlong().includes(featureClass.Id)
                }            
                return false
            })
        }
    }

    return possibleDevices
}

export const calculateForAdditionalData = (possibleDevices, additionalData, orientation) => {
    return possibleDevices.filter(device => {
        let additionalCplateData = additionalData.find(el => el.supplier_pid === device.supplierPid)
        if (additionalCplateData) {
            return additionalCplateData.orientation === orientation
                || ((orientation === 'left' || orientation === 'right') && additionalCplateData.orientation === 'both')
        }
        return true
    })
}

export const calculateDeviceForCPlate = (supplierPid, deviceList, features, articleColor, additionalData, additionalArticles, orientation, serieId) => {
    const calculatedAdditionalArticles = getAdditionalArticles(additionalArticles, deviceList)
    
    const possibleForAddionalArticles = checkPossibleForAddionalArticles(calculatedAdditionalArticles)

    let {description, descriptions} = calculateDescritpions1(supplierPid, articleColor, calculatedAdditionalArticles, additionalData, serieId, features, possibleForAddionalArticles)

    let possibleDevices = calculateDescritpions2(supplierPid, features, possibleForAddionalArticles, deviceList, description, descriptions, additionalData, serieId, calculatedAdditionalArticles, articleColor)
    
    return calculateForAdditionalData(possibleDevices, additionalData, orientation)

}

