import { dehydrate, hydrate } from 'react-query/hydration'
import { FETCH_CENTRALPLATE, FETCH_IMAGE } from '../components/api'

export async function persistQueryClient({ queryClient, persistor, maxAge = 1000 * 60 * 60 * 24, buster = '' }) {
    if (typeof window !== 'undefined') {
        // Subscribe to changes
        const saveClient = () => {
            const persistClient = {
                buster,
                timestamp: Date.now(),
                clientState: dehydrate(queryClient),
            }

            persistor.persistClient(persistClient)
        }

        // Attempt restore
        try {
            const persistedClient = await persistor.restoreClient()

            if (persistedClient) {
                if (persistedClient.timestamp) {
                    const expired = Date.now() - persistedClient.timestamp > maxAge
                    const busted = persistedClient.buster !== buster
                    if (expired || busted) {
                        persistor.removeClient()
                    } else {
                        hydrate(queryClient, persistedClient.clientState)
                    }
                } else {
                    persistor.removeClient()
                }
            }
        } catch (err) {
            persistor.removeClient()
        }

        // Subscribe to changes in the query cache to trigger the save
        queryClient.getQueryCache().subscribe(saveClient)
    }
}

export function createLocalStoragePersistor({
    localStorageKey = `REACT_QUERY_OFFLINE_CACHE`,
    throttleTime = 1000,
} = {}) {
    if (typeof localStorage !== 'undefined') {
        return {
            persistClient: throttle(persistedClient => {
                const filteredQueries = persistedClient.clientState.queries.filter(query => {
                    const key = query.queryKey
                    if (Array.isArray(key)) {
                        return !key.includes(FETCH_IMAGE) && !key.includes(FETCH_CENTRALPLATE)
                    } else {
                        return key !== FETCH_IMAGE && !key.includes(FETCH_CENTRALPLATE)
                    }
                })

                const filteredClient = {
                    ...persistedClient,
                    clientState: {
                        ...persistedClient.clientState,
                        queries: filteredQueries,
                    }
                }

                localStorage.setItem(localStorageKey, JSON.stringify(filteredClient))
            }, throttleTime),
            restoreClient: () => {
                const cacheString = localStorage.getItem(localStorageKey)

                if (!cacheString) {
                    return
                }

                return JSON.parse(cacheString)
            },
            removeClient: () => {
                localStorage.removeItem(localStorageKey)
            },
        }
    }

    return {
        persistClient: () => {},
        restoreClient: () => {},
        removeClient: () => {},
    }
}

function throttle(func, wait = 100) {
    let timer = null

    return function(...args) {
        if (timer === null) {
            timer = setTimeout(() => {
                func(...args)
                timer = null
            }, wait)
        }
    }
}
