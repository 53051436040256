import { combineReducers } from 'redux';
import {enableBatching} from 'redux-batched-actions';
import availableDevices from '../components/availableDevices/duck'
import elbridge from '../components/elbridge/duck'
import entities from '../components/entities/duck'
import favorites from '../components/favorites/duck'
import projects from '../components/projects/duck'

const RESET_APP = 'raumplaner/rootReducer/RESET_APP';

const reducers = {
  availableDevices,
  elbridge,
  entities,
  favorites,
  projects,
};

const appReducer = combineReducers({
  ...reducers,
});

const rootReducer = (state, action) => {
  if(action.type === RESET_APP) {
    state = undefined;
  }

  return appReducer(state, action);
};

export const resetApp = () => {
  return {
    type: RESET_APP,
  }
}

export default enableBatching(rootReducer);
