import { getParentDesignRange } from '../components/entities/duck'
import { initialState as operationPointState } from '../components/entities/operationPoint/duck'
import { initialState as deviceState } from '../components/entities/device/duck'

export const migration = currentState => {
    if (currentState) {
        let entities = currentState.entities.byId

        if (entities) {
            Object.keys(entities).forEach(key => {
                let entity = JSON.parse(JSON.stringify(entities[key]))
                if (entity.type === 'device' || entity.type === 'frame' || entity.type === 'cplate') {
                    entity = { ...deviceState, ...entity }
                }
                if (entity.type === 'operationPoint') {
                    entity = { ...operationPointState, ...entity }
                }

                if (['floor', 'room', 'operationPoint'].includes(entity.type)) {
                    if (!entity.config) {
                        entity.config = []
                    }

                    if (!entity.config.find(el => el.type === 'serie')
                        || !entity.config.find(el => el.type === 'frameColor')
                        || !entity.config.find(el => el.type === 'cplateColor')
                    ) {
                        const parentDesignRange = getParentDesignRange(currentState, entity.id)
                        if (!entity.config.find(el => el.type === 'serie')) {
                            entity.config.push({ type: 'serie', value: parentDesignRange.serie})
                        }

                        if (!entity.config.find(el => el.type === 'frameColor')) {
                            entity.config.push({ type: 'frameColor', value: parentDesignRange.frameColor})
                        }
    
                        if (!entity.config.find(el => el.type === 'cplateColor')) {
                            entity.config.push({ type: 'cplateColor', value: parentDesignRange.cplateColor})
                        }
                    }
                }

                entities[key] = entity
            })
            currentState.entities.byId = entities
        }
    }

    return currentState
}
