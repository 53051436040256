import v4 from 'node-uuid'
import last from 'lodash/last'
import omit from 'lodash/omit'
import {batchActions} from 'redux-batched-actions'

import {buildFloorBuildingReference} from '../building/duck'
import {generateDeleteSequence} from '../helpers'
import {createRoom} from '../room/duck'
import { setConfigValue } from '../duck'

// Action Types
//
export const ADD_FLOOR = 'raumplaner/entities/FLOOR/ADD'
export const CLONE_FLOOR = 'raumplaner/entities/FLOOR/CLONE'
export const REMOVE_FLOOR = 'raumplaner/entities/FLOOR/REMOVE'

export const FLOOR_REFERENCE_ROOM = 'raumplaner/entities/FLOOR/REFERENCE_ROOM'
export const FLOOR_DEREFERENCE_ROOM = 'raumplaner/entities/FLOOR/DEREFERENCE_ROOM'

export const SET_ACTIVE_ROOM = 'raumplaner/entities/FLOOR/SET_ACTIVE_ROOM'

// Reducer
//
export const floorInitialState = {
    type: 'floor',
    id: null,
    label: null,
    parent: null,
    rooms: [],
    config: [],
    activeRoom: null,
}

function reducer(state = floorInitialState, action) {
    let roomIndex
    let rooms
    switch (action.type) {
        case CLONE_FLOOR:
            return {
                ...state,
                id: action.floorId,
                parent: action.buildingId,
                rooms: floorInitialState.rooms,
                activeRoom: floorInitialState.activeRoom,
            }
        case ADD_FLOOR:
            return {
                ...state,
                id: action.floorId,
                parent: action.buildingId,
            }
        case FLOOR_REFERENCE_ROOM:
            return {
                ...state,
                rooms: [...state.rooms, action.roomId],
            }
        case FLOOR_DEREFERENCE_ROOM:
            roomIndex = state.rooms.indexOf(action.roomId)
            rooms = [...state.rooms.slice(0, roomIndex), ...state.rooms.slice(roomIndex + 1)]
            return {
                ...state,
                rooms: rooms,
                activeRoom: rooms[roomIndex] ? rooms[roomIndex] : last(rooms),
            }
        case SET_ACTIVE_ROOM:
            return {
                ...state,
                activeRoom: action.roomId,
            }
        default:
            return state
    }
}

export default function floorListReducer(state = {byId: {}, allIds: []}, action) {
    let allIdIndex
    switch (action.type) {
        case CLONE_FLOOR:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.floorId]: reducer(state.byId[action.originFloorId], action),
                },
                allIds: [...state.allIds, action.floorId],
            }
        case ADD_FLOOR:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.floorId]: reducer(state.byId[action.floorId], action),
                },
                allIds: [...state.allIds, action.floorId],
            }
        //absolute pass
        case FLOOR_REFERENCE_ROOM:
        case FLOOR_DEREFERENCE_ROOM:
        case SET_ACTIVE_ROOM:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.floorId]: reducer(state.byId[action.floorId], action),
                },
            }
        case REMOVE_FLOOR:
            allIdIndex = state.allIds.indexOf(action.floorId)
            return {
                ...state,
                byId: omit(state.byId, [action.floorId]),
                allIds: [...state.allIds.slice(0, allIdIndex), ...state.allIds.slice(allIdIndex + 1)],
            }
        default:
            return state
    }
}

// Action Creators
//
export const addFloor = (buildingId, floorId) => {
    return {
        type: ADD_FLOOR,
        floorId,
        buildingId,
    }
}

export const deleteFloor = floorId => {
    return {
        type: REMOVE_FLOOR,
        floorId,
    }
}

export const removeFloor = floor => {
    return (dispatch, getState) => {
        return dispatch(generateDeleteSequence(getState(), floor, 0, []))
    }
}

export const createFloorWithRoom = (
    buildingId,
    floorId = v4(),
    roomId = v4(),
    serie,
    frameColor,
    cPlateColor,
) => {
    const sequence = [
        createFloor(buildingId, floorId, serie, frameColor, cPlateColor),
        createRoom(floorId, roomId, serie, frameColor, cPlateColor),
    ]

    return batchActions(sequence)
}

export const createFloor = (
    buildingId,
    floorId = v4(),
    serie,
    frameColor,
    cPlateColor,
) => {
    const sequence = [
        addFloor(buildingId, floorId),
        buildFloorBuildingReference(buildingId, floorId),
    ]

    if (serie) {
        sequence.push(setConfigValue(floorId, {type: 'serie', value: serie}))
    }

    if (frameColor) {
        setConfigValue(floorId, {type: 'frameColor', value: frameColor})
    }

    if (cPlateColor) {
        setConfigValue(floorId, {type: 'cplateColor', value: cPlateColor})
    }

    return batchActions(sequence)
}

export const referenceRoomWithFloor = (floorId, roomId) => {
    return {
        type: FLOOR_REFERENCE_ROOM,
        floorId,
        roomId,
    }
}

export const dereferenceRoomWithFloor = (floorId, roomId) => {
    return {
        type: FLOOR_DEREFERENCE_ROOM,
        floorId,
        roomId,
    }
}

export const setActiveRoom = (floorId, roomId) => {
    return {
        type: SET_ACTIVE_ROOM,
        floorId,
        roomId,
    }
}

export const buildRoomFloorReference = (floorId, roomId) => {
    if (!floorId) {
        return batchActions([])
    }

    return batchActions([referenceRoomWithFloor(floorId, roomId), setActiveRoom(floorId, roomId)])
}

export const removeRoomFloorReference = (floorId, roomId) => {
    return dereferenceRoomWithFloor(floorId, roomId)
}
export const copyFloor = (buildingId, originFloorId, floorId = v4()) => {
    return {
        type: CLONE_FLOOR,
        buildingId,
        originFloorId,
        floorId,
    }
}

export const cloneFloor = (buildingId, originFloorId, floorId = v4()) => {
    const sequence = [copyFloor(buildingId, originFloorId, floorId)]
    return batchActions(sequence)
}
