// source: model/etim.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.model.DeviceProto', null, global);
goog.exportSymbol('proto.model.DeviceProto.DiscountGroup', null, global);
goog.exportSymbol('proto.model.DeviceProto.MimeDetails', null, global);
goog.exportSymbol('proto.model.DeviceProto.Mime_infos', null, global);
goog.exportSymbol('proto.model.DeviceProto.PackagingUnit', null, global);
goog.exportSymbol('proto.model.DeviceProto.PriceDetails', null, global);
goog.exportSymbol('proto.model.DeviceProto.Prices', null, global);
goog.exportSymbol('proto.model.DeviceProto.UserDefinedExtensions', null, global);
goog.exportSymbol('proto.model.DevicesProto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DeviceProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DeviceProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DeviceProto.displayName = 'proto.model.DeviceProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DeviceProto.DiscountGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DeviceProto.DiscountGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DeviceProto.DiscountGroup.displayName = 'proto.model.DeviceProto.DiscountGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DeviceProto.PackagingUnit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DeviceProto.PackagingUnit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DeviceProto.PackagingUnit.displayName = 'proto.model.DeviceProto.PackagingUnit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DeviceProto.UserDefinedExtensions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.DeviceProto.UserDefinedExtensions.repeatedFields_, null);
};
goog.inherits(proto.model.DeviceProto.UserDefinedExtensions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DeviceProto.UserDefinedExtensions.displayName = 'proto.model.DeviceProto.UserDefinedExtensions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DeviceProto.Prices = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DeviceProto.Prices, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DeviceProto.Prices.displayName = 'proto.model.DeviceProto.Prices';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DeviceProto.PriceDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.DeviceProto.PriceDetails.repeatedFields_, null);
};
goog.inherits(proto.model.DeviceProto.PriceDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DeviceProto.PriceDetails.displayName = 'proto.model.DeviceProto.PriceDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DeviceProto.Mime_infos = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DeviceProto.Mime_infos, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DeviceProto.Mime_infos.displayName = 'proto.model.DeviceProto.Mime_infos';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DeviceProto.MimeDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.DeviceProto.MimeDetails.repeatedFields_, null);
};
goog.inherits(proto.model.DeviceProto.MimeDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DeviceProto.MimeDetails.displayName = 'proto.model.DeviceProto.MimeDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DevicesProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.DevicesProto.repeatedFields_, null);
};
goog.inherits(proto.model.DevicesProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DevicesProto.displayName = 'proto.model.DevicesProto';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DeviceProto.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DeviceProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DeviceProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    internationalpid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    manufacturername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    descriptionshort: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descriptionlong: jspb.Message.getFieldWithDefault(msg, 4, ""),
    supplieraltpid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    manufacturerpid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    manufacturertypedescr: jspb.Message.getFieldWithDefault(msg, 7, ""),
    supplierpid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pricedetails: (f = msg.getPricedetails()) && proto.model.DeviceProto.PriceDetails.toObject(includeInstance, f),
    userdefinedextensions: (f = msg.getUserdefinedextensions()) && proto.model.DeviceProto.UserDefinedExtensions.toObject(includeInstance, f),
    dividingunit: jspb.Message.getFieldWithDefault(msg, 11, ""),
    knxteilnehmer: jspb.Message.getFieldWithDefault(msg, 12, 0),
    color: jspb.Message.getFieldWithDefault(msg, 13, ""),
    mimedetails: (f = msg.getMimedetails()) && proto.model.DeviceProto.MimeDetails.toObject(includeInstance, f),
    features: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DeviceProto}
 */
proto.model.DeviceProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DeviceProto;
  return proto.model.DeviceProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DeviceProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DeviceProto}
 */
proto.model.DeviceProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setInternationalpid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionshort(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionlong(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplieraltpid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturerpid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturertypedescr(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierpid(value);
      break;
    case 9:
      var value = new proto.model.DeviceProto.PriceDetails;
      reader.readMessage(value,proto.model.DeviceProto.PriceDetails.deserializeBinaryFromReader);
      msg.setPricedetails(value);
      break;
    case 10:
      var value = new proto.model.DeviceProto.UserDefinedExtensions;
      reader.readMessage(value,proto.model.DeviceProto.UserDefinedExtensions.deserializeBinaryFromReader);
      msg.setUserdefinedextensions(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDividingunit(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKnxteilnehmer(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 14:
      var value = new proto.model.DeviceProto.MimeDetails;
      reader.readMessage(value,proto.model.DeviceProto.MimeDetails.deserializeBinaryFromReader);
      msg.setMimedetails(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DeviceProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DeviceProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DeviceProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternationalpid();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getManufacturername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescriptionshort();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescriptionlong();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSupplieraltpid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getManufacturerpid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getManufacturertypedescr();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSupplierpid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPricedetails();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.model.DeviceProto.PriceDetails.serializeBinaryToWriter
    );
  }
  f = message.getUserdefinedextensions();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.model.DeviceProto.UserDefinedExtensions.serializeBinaryToWriter
    );
  }
  f = message.getDividingunit();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getKnxteilnehmer();
  if (f !== 0) {
    writer.writeUint64(
      12,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMimedetails();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.model.DeviceProto.MimeDetails.serializeBinaryToWriter
    );
  }
  f = message.getFeatures();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DeviceProto.DiscountGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DeviceProto.DiscountGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DeviceProto.DiscountGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.DiscountGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupmanufacturer: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DeviceProto.DiscountGroup}
 */
proto.model.DeviceProto.DiscountGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DeviceProto.DiscountGroup;
  return proto.model.DeviceProto.DiscountGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DeviceProto.DiscountGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DeviceProto.DiscountGroup}
 */
proto.model.DeviceProto.DiscountGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupmanufacturer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DeviceProto.DiscountGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DeviceProto.DiscountGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DeviceProto.DiscountGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.DiscountGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupmanufacturer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string groupManufacturer = 1;
 * @return {string}
 */
proto.model.DeviceProto.DiscountGroup.prototype.getGroupmanufacturer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto.DiscountGroup} returns this
 */
proto.model.DeviceProto.DiscountGroup.prototype.setGroupmanufacturer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DeviceProto.PackagingUnit.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DeviceProto.PackagingUnit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DeviceProto.PackagingUnit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.PackagingUnit.toObject = function(includeInstance, msg) {
  var f, obj = {
    gtin: jspb.Message.getFieldWithDefault(msg, 1, ""),
    depth: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    length: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    quantitymax: jspb.Message.getFieldWithDefault(msg, 6, 0),
    quantitymin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    packageunitcode: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DeviceProto.PackagingUnit}
 */
proto.model.DeviceProto.PackagingUnit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DeviceProto.PackagingUnit;
  return proto.model.DeviceProto.PackagingUnit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DeviceProto.PackagingUnit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DeviceProto.PackagingUnit}
 */
proto.model.DeviceProto.PackagingUnit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGtin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDepth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLength(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeight(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantitymax(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantitymin(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageunitcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DeviceProto.PackagingUnit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DeviceProto.PackagingUnit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DeviceProto.PackagingUnit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.PackagingUnit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGtin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLength();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getQuantitymax();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getQuantitymin();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPackageunitcode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string gtin = 1;
 * @return {string}
 */
proto.model.DeviceProto.PackagingUnit.prototype.getGtin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto.PackagingUnit} returns this
 */
proto.model.DeviceProto.PackagingUnit.prototype.setGtin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float depth = 2;
 * @return {number}
 */
proto.model.DeviceProto.PackagingUnit.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.model.DeviceProto.PackagingUnit} returns this
 */
proto.model.DeviceProto.PackagingUnit.prototype.setDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float width = 3;
 * @return {number}
 */
proto.model.DeviceProto.PackagingUnit.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.model.DeviceProto.PackagingUnit} returns this
 */
proto.model.DeviceProto.PackagingUnit.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float length = 4;
 * @return {number}
 */
proto.model.DeviceProto.PackagingUnit.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.model.DeviceProto.PackagingUnit} returns this
 */
proto.model.DeviceProto.PackagingUnit.prototype.setLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float weight = 5;
 * @return {number}
 */
proto.model.DeviceProto.PackagingUnit.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.model.DeviceProto.PackagingUnit} returns this
 */
proto.model.DeviceProto.PackagingUnit.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 quantityMax = 6;
 * @return {number}
 */
proto.model.DeviceProto.PackagingUnit.prototype.getQuantitymax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.DeviceProto.PackagingUnit} returns this
 */
proto.model.DeviceProto.PackagingUnit.prototype.setQuantitymax = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 quantityMin = 7;
 * @return {number}
 */
proto.model.DeviceProto.PackagingUnit.prototype.getQuantitymin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.DeviceProto.PackagingUnit} returns this
 */
proto.model.DeviceProto.PackagingUnit.prototype.setQuantitymin = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string packageUnitCode = 8;
 * @return {string}
 */
proto.model.DeviceProto.PackagingUnit.prototype.getPackageunitcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto.PackagingUnit} returns this
 */
proto.model.DeviceProto.PackagingUnit.prototype.setPackageunitcode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.DeviceProto.UserDefinedExtensions.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DeviceProto.UserDefinedExtensions.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DeviceProto.UserDefinedExtensions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DeviceProto.UserDefinedExtensions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.UserDefinedExtensions.toObject = function(includeInstance, msg) {
  var f, obj = {
    discountgroupList: jspb.Message.toObjectList(msg.getDiscountgroupList(),
    proto.model.DeviceProto.DiscountGroup.toObject, includeInstance),
    packagingunitList: jspb.Message.toObjectList(msg.getPackagingunitList(),
    proto.model.DeviceProto.PackagingUnit.toObject, includeInstance),
    veryshortdescription: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DeviceProto.UserDefinedExtensions}
 */
proto.model.DeviceProto.UserDefinedExtensions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DeviceProto.UserDefinedExtensions;
  return proto.model.DeviceProto.UserDefinedExtensions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DeviceProto.UserDefinedExtensions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DeviceProto.UserDefinedExtensions}
 */
proto.model.DeviceProto.UserDefinedExtensions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.DeviceProto.DiscountGroup;
      reader.readMessage(value,proto.model.DeviceProto.DiscountGroup.deserializeBinaryFromReader);
      msg.addDiscountgroup(value);
      break;
    case 2:
      var value = new proto.model.DeviceProto.PackagingUnit;
      reader.readMessage(value,proto.model.DeviceProto.PackagingUnit.deserializeBinaryFromReader);
      msg.addPackagingunit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVeryshortdescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DeviceProto.UserDefinedExtensions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DeviceProto.UserDefinedExtensions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DeviceProto.UserDefinedExtensions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.UserDefinedExtensions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscountgroupList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.DeviceProto.DiscountGroup.serializeBinaryToWriter
    );
  }
  f = message.getPackagingunitList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.model.DeviceProto.PackagingUnit.serializeBinaryToWriter
    );
  }
  f = message.getVeryshortdescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated DiscountGroup discountGroup = 1;
 * @return {!Array<!proto.model.DeviceProto.DiscountGroup>}
 */
proto.model.DeviceProto.UserDefinedExtensions.prototype.getDiscountgroupList = function() {
  return /** @type{!Array<!proto.model.DeviceProto.DiscountGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.DeviceProto.DiscountGroup, 1));
};


/**
 * @param {!Array<!proto.model.DeviceProto.DiscountGroup>} value
 * @return {!proto.model.DeviceProto.UserDefinedExtensions} returns this
*/
proto.model.DeviceProto.UserDefinedExtensions.prototype.setDiscountgroupList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.DeviceProto.DiscountGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DeviceProto.DiscountGroup}
 */
proto.model.DeviceProto.UserDefinedExtensions.prototype.addDiscountgroup = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.DeviceProto.DiscountGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DeviceProto.UserDefinedExtensions} returns this
 */
proto.model.DeviceProto.UserDefinedExtensions.prototype.clearDiscountgroupList = function() {
  return this.setDiscountgroupList([]);
};


/**
 * repeated PackagingUnit packagingUnit = 2;
 * @return {!Array<!proto.model.DeviceProto.PackagingUnit>}
 */
proto.model.DeviceProto.UserDefinedExtensions.prototype.getPackagingunitList = function() {
  return /** @type{!Array<!proto.model.DeviceProto.PackagingUnit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.DeviceProto.PackagingUnit, 2));
};


/**
 * @param {!Array<!proto.model.DeviceProto.PackagingUnit>} value
 * @return {!proto.model.DeviceProto.UserDefinedExtensions} returns this
*/
proto.model.DeviceProto.UserDefinedExtensions.prototype.setPackagingunitList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.DeviceProto.PackagingUnit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DeviceProto.PackagingUnit}
 */
proto.model.DeviceProto.UserDefinedExtensions.prototype.addPackagingunit = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.DeviceProto.PackagingUnit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DeviceProto.UserDefinedExtensions} returns this
 */
proto.model.DeviceProto.UserDefinedExtensions.prototype.clearPackagingunitList = function() {
  return this.setPackagingunitList([]);
};


/**
 * optional string veryShortDescription = 3;
 * @return {string}
 */
proto.model.DeviceProto.UserDefinedExtensions.prototype.getVeryshortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto.UserDefinedExtensions} returns this
 */
proto.model.DeviceProto.UserDefinedExtensions.prototype.setVeryshortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DeviceProto.Prices.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DeviceProto.Prices.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DeviceProto.Prices} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.Prices.toObject = function(includeInstance, msg) {
  var f, obj = {
    tax: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DeviceProto.Prices}
 */
proto.model.DeviceProto.Prices.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DeviceProto.Prices;
  return proto.model.DeviceProto.Prices.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DeviceProto.Prices} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DeviceProto.Prices}
 */
proto.model.DeviceProto.Prices.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTax(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DeviceProto.Prices.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DeviceProto.Prices.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DeviceProto.Prices} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.Prices.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTax();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional float tax = 1;
 * @return {number}
 */
proto.model.DeviceProto.Prices.prototype.getTax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.model.DeviceProto.Prices} returns this
 */
proto.model.DeviceProto.Prices.prototype.setTax = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float amount = 2;
 * @return {number}
 */
proto.model.DeviceProto.Prices.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.model.DeviceProto.Prices} returns this
 */
proto.model.DeviceProto.Prices.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.model.DeviceProto.Prices.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto.Prices} returns this
 */
proto.model.DeviceProto.Prices.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.DeviceProto.PriceDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DeviceProto.PriceDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DeviceProto.PriceDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DeviceProto.PriceDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.PriceDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricesList: jspb.Message.toObjectList(msg.getPricesList(),
    proto.model.DeviceProto.Prices.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DeviceProto.PriceDetails}
 */
proto.model.DeviceProto.PriceDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DeviceProto.PriceDetails;
  return proto.model.DeviceProto.PriceDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DeviceProto.PriceDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DeviceProto.PriceDetails}
 */
proto.model.DeviceProto.PriceDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.DeviceProto.Prices;
      reader.readMessage(value,proto.model.DeviceProto.Prices.deserializeBinaryFromReader);
      msg.addPrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DeviceProto.PriceDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DeviceProto.PriceDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DeviceProto.PriceDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.PriceDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.DeviceProto.Prices.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Prices prices = 1;
 * @return {!Array<!proto.model.DeviceProto.Prices>}
 */
proto.model.DeviceProto.PriceDetails.prototype.getPricesList = function() {
  return /** @type{!Array<!proto.model.DeviceProto.Prices>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.DeviceProto.Prices, 1));
};


/**
 * @param {!Array<!proto.model.DeviceProto.Prices>} value
 * @return {!proto.model.DeviceProto.PriceDetails} returns this
*/
proto.model.DeviceProto.PriceDetails.prototype.setPricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.DeviceProto.Prices=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DeviceProto.Prices}
 */
proto.model.DeviceProto.PriceDetails.prototype.addPrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.DeviceProto.Prices, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DeviceProto.PriceDetails} returns this
 */
proto.model.DeviceProto.PriceDetails.prototype.clearPricesList = function() {
  return this.setPricesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DeviceProto.Mime_infos.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DeviceProto.Mime_infos.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DeviceProto.Mime_infos} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.Mime_infos.toObject = function(includeInstance, msg) {
  var f, obj = {
    desc: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    source: jspb.Message.getFieldWithDefault(msg, 3, ""),
    purpose: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DeviceProto.Mime_infos}
 */
proto.model.DeviceProto.Mime_infos.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DeviceProto.Mime_infos;
  return proto.model.DeviceProto.Mime_infos.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DeviceProto.Mime_infos} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DeviceProto.Mime_infos}
 */
proto.model.DeviceProto.Mime_infos.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesc(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DeviceProto.Mime_infos.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DeviceProto.Mime_infos.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DeviceProto.Mime_infos} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.Mime_infos.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string Desc = 1;
 * @return {string}
 */
proto.model.DeviceProto.Mime_infos.prototype.getDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto.Mime_infos} returns this
 */
proto.model.DeviceProto.Mime_infos.prototype.setDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Type = 2;
 * @return {string}
 */
proto.model.DeviceProto.Mime_infos.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto.Mime_infos} returns this
 */
proto.model.DeviceProto.Mime_infos.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Source = 3;
 * @return {string}
 */
proto.model.DeviceProto.Mime_infos.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto.Mime_infos} returns this
 */
proto.model.DeviceProto.Mime_infos.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Purpose = 4;
 * @return {string}
 */
proto.model.DeviceProto.Mime_infos.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto.Mime_infos} returns this
 */
proto.model.DeviceProto.Mime_infos.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.DeviceProto.MimeDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DeviceProto.MimeDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DeviceProto.MimeDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DeviceProto.MimeDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.MimeDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    mimeInfosList: jspb.Message.toObjectList(msg.getMimeInfosList(),
    proto.model.DeviceProto.Mime_infos.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DeviceProto.MimeDetails}
 */
proto.model.DeviceProto.MimeDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DeviceProto.MimeDetails;
  return proto.model.DeviceProto.MimeDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DeviceProto.MimeDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DeviceProto.MimeDetails}
 */
proto.model.DeviceProto.MimeDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.DeviceProto.Mime_infos;
      reader.readMessage(value,proto.model.DeviceProto.Mime_infos.deserializeBinaryFromReader);
      msg.addMimeInfos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DeviceProto.MimeDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DeviceProto.MimeDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DeviceProto.MimeDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DeviceProto.MimeDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMimeInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.DeviceProto.Mime_infos.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Mime_infos mime_infos = 1;
 * @return {!Array<!proto.model.DeviceProto.Mime_infos>}
 */
proto.model.DeviceProto.MimeDetails.prototype.getMimeInfosList = function() {
  return /** @type{!Array<!proto.model.DeviceProto.Mime_infos>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.DeviceProto.Mime_infos, 1));
};


/**
 * @param {!Array<!proto.model.DeviceProto.Mime_infos>} value
 * @return {!proto.model.DeviceProto.MimeDetails} returns this
*/
proto.model.DeviceProto.MimeDetails.prototype.setMimeInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.DeviceProto.Mime_infos=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DeviceProto.Mime_infos}
 */
proto.model.DeviceProto.MimeDetails.prototype.addMimeInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.DeviceProto.Mime_infos, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DeviceProto.MimeDetails} returns this
 */
proto.model.DeviceProto.MimeDetails.prototype.clearMimeInfosList = function() {
  return this.setMimeInfosList([]);
};


/**
 * optional uint64 internationalPid = 1;
 * @return {number}
 */
proto.model.DeviceProto.prototype.getInternationalpid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setInternationalpid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string manufacturerName = 2;
 * @return {string}
 */
proto.model.DeviceProto.prototype.getManufacturername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setManufacturername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string descriptionShort = 3;
 * @return {string}
 */
proto.model.DeviceProto.prototype.getDescriptionshort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setDescriptionshort = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string descriptionLong = 4;
 * @return {string}
 */
proto.model.DeviceProto.prototype.getDescriptionlong = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setDescriptionlong = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string supplierAltPid = 5;
 * @return {string}
 */
proto.model.DeviceProto.prototype.getSupplieraltpid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setSupplieraltpid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string manufacturerPid = 6;
 * @return {string}
 */
proto.model.DeviceProto.prototype.getManufacturerpid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setManufacturerpid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string manufacturerTypeDescr = 7;
 * @return {string}
 */
proto.model.DeviceProto.prototype.getManufacturertypedescr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setManufacturertypedescr = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string supplierPid = 8;
 * @return {string}
 */
proto.model.DeviceProto.prototype.getSupplierpid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setSupplierpid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional PriceDetails priceDetails = 9;
 * @return {?proto.model.DeviceProto.PriceDetails}
 */
proto.model.DeviceProto.prototype.getPricedetails = function() {
  return /** @type{?proto.model.DeviceProto.PriceDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.DeviceProto.PriceDetails, 9));
};


/**
 * @param {?proto.model.DeviceProto.PriceDetails|undefined} value
 * @return {!proto.model.DeviceProto} returns this
*/
proto.model.DeviceProto.prototype.setPricedetails = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.clearPricedetails = function() {
  return this.setPricedetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DeviceProto.prototype.hasPricedetails = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional UserDefinedExtensions userDefinedExtensions = 10;
 * @return {?proto.model.DeviceProto.UserDefinedExtensions}
 */
proto.model.DeviceProto.prototype.getUserdefinedextensions = function() {
  return /** @type{?proto.model.DeviceProto.UserDefinedExtensions} */ (
    jspb.Message.getWrapperField(this, proto.model.DeviceProto.UserDefinedExtensions, 10));
};


/**
 * @param {?proto.model.DeviceProto.UserDefinedExtensions|undefined} value
 * @return {!proto.model.DeviceProto} returns this
*/
proto.model.DeviceProto.prototype.setUserdefinedextensions = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.clearUserdefinedextensions = function() {
  return this.setUserdefinedextensions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DeviceProto.prototype.hasUserdefinedextensions = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string dividingUnit = 11;
 * @return {string}
 */
proto.model.DeviceProto.prototype.getDividingunit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setDividingunit = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint64 knxTeilnehmer = 12;
 * @return {number}
 */
proto.model.DeviceProto.prototype.getKnxteilnehmer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setKnxteilnehmer = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string color = 13;
 * @return {string}
 */
proto.model.DeviceProto.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional MimeDetails mimeDetails = 14;
 * @return {?proto.model.DeviceProto.MimeDetails}
 */
proto.model.DeviceProto.prototype.getMimedetails = function() {
  return /** @type{?proto.model.DeviceProto.MimeDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.DeviceProto.MimeDetails, 14));
};


/**
 * @param {?proto.model.DeviceProto.MimeDetails|undefined} value
 * @return {!proto.model.DeviceProto} returns this
*/
proto.model.DeviceProto.prototype.setMimedetails = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.clearMimedetails = function() {
  return this.setMimedetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DeviceProto.prototype.hasMimedetails = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string Features = 15;
 * @return {string}
 */
proto.model.DeviceProto.prototype.getFeatures = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DeviceProto} returns this
 */
proto.model.DeviceProto.prototype.setFeatures = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.DevicesProto.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DevicesProto.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DevicesProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DevicesProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DevicesProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.model.DeviceProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DevicesProto}
 */
proto.model.DevicesProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DevicesProto;
  return proto.model.DevicesProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DevicesProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DevicesProto}
 */
proto.model.DevicesProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.DeviceProto;
      reader.readMessage(value,proto.model.DeviceProto.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DevicesProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DevicesProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DevicesProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DevicesProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.DeviceProto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeviceProto devices = 1;
 * @return {!Array<!proto.model.DeviceProto>}
 */
proto.model.DevicesProto.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.model.DeviceProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.DeviceProto, 1));
};


/**
 * @param {!Array<!proto.model.DeviceProto>} value
 * @return {!proto.model.DevicesProto} returns this
*/
proto.model.DevicesProto.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.DeviceProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DeviceProto}
 */
proto.model.DevicesProto.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.DeviceProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DevicesProto} returns this
 */
proto.model.DevicesProto.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


goog.object.extend(exports, proto.model);
