import v4 from 'node-uuid'
import omit from 'lodash/omit'
import { batchActions } from 'redux-batched-actions'
import { buildOperationPointRoomReference } from '../room/duck'
import { getActiveRoom, getAllEntities, getEntityResolved, getIsSocket, getParentDesignRange, setConfigValue } from '../duck'
import { generateDeleteSequence } from '../helpers/index'
import { equals } from 'ramda'
import { getEditableProject } from '../../projects/duck'
import { addDeviceToOperationPoint, addFrameToOpertaionPoint } from '../device/duck'
import { createDeepEqualSelector } from '../../../vendor/qlib'
import { calculateFrame } from '../../../logic/calculateFrame'
import { getDeviceList, getFrameMapping, getDevicesWithoutFrame } from '../../availableDevices/duck'

// Actions
//
export const ADD_OPERATION_POINT = 'raumplaner/entities/OPERATION_POINT/ADD'
export const CLONE_OPERATION_POINT = 'raumplaner/entities/OPERATION_POINT/CLONE'
export const REMOVE_OPERATION_POINT = 'raumplaner/entities/OPERATION_POINT/REMOVE'
export const OPERATION_POINT_REFERENCE_DEVICE = 'raumplaner/entities/ROOPERATION_POINTOM/REFERENCE_DEVICE'
export const OPERATION_POINT_DEREFERENCE_DEVICE = 'raumplaner/entities/OPERATION_POINT/DEREFERENCE_DEVICE'
export const OPERATION_POINT_REFERENCE_FRAME = 'raumplaner/entities/ROOPERATION_POINTOM/REFERENCE_FRAME'
export const OPERATION_POINT_DEREFERENCE_FRAME = 'raumplaner/entities/OPERATION_POINT/DEREFERENCE_FRAME'
export const REORDER_DEVICES = 'raumplaner/entities/OPERATION_POINT/REORDER_DEVICES'
export const SET_OPERATION_POINT_QUANTITY = 'raumplaner/entities/OPERATION_POINT/SET_OPERATION_POINT_QUANTITY'
export const ADD_OPERATION_POINT_CONFIG = 'raumplaner/entities/OPERATION_POINT/ADD_OPERATION_POINT_CONFIG'
export const DEL_OPERATION_POINT_CONFIG = 'raumplaner/entities/OPERATION_POINT/DEL_OPERATION_POINT_CONFIG'
export const SET_OPERATIONPOINT_IS_UPDATING = 'raumplaner/entities/OPERATION_POINT/SET_IS_UPDATING'

// Reducer
//
export const initialState = {
    type: 'operationPoint',
    id: null,
    parent: null,
    label: null,
    devices: [],
    frame: null,
    config: [],
    quantity: 1,
    isUpdating: false,
}

function reducer(state = initialState, action) {
    let deviceIndex
    switch (action.type) {
        case CLONE_OPERATION_POINT:
            return {
                ...state,
                id: action.operationPointId,
                parent: action.roomId,
                devices: initialState.devices,
                frame: initialState.frame,
            }
        case ADD_OPERATION_POINT:
            return {
                ...state,
                id: action.operationPointId,
                parent: action.roomId,
            }
        case OPERATION_POINT_REFERENCE_DEVICE:
            if (!isNaN(parseInt(action.index)) && action.index >= 0) {
                return {
                    ...state,
                    devices: [
                        ...state.devices.slice(0, action.index),
                        action.deviceId,
                        ...state.devices.slice(action.index),
                    ],
                }
            }
            return {
                ...state,
                devices: [...state.devices, action.deviceId],
            }
        case OPERATION_POINT_DEREFERENCE_DEVICE:
            deviceIndex = state.devices.indexOf(action.deviceId)
            return {
                ...state,
                devices: [...state.devices.slice(0, deviceIndex), ...state.devices.slice(deviceIndex + 1)],
            }
        case OPERATION_POINT_REFERENCE_FRAME:
            return {
                ...state,
                frame: action.deviceId,
            }
        case OPERATION_POINT_DEREFERENCE_FRAME:
            return {
                ...state,
                frame: null,
            }
        case REORDER_DEVICES: {
            return {
                ...state,
                devices: action.devices,
            }
        }
        case SET_OPERATION_POINT_QUANTITY: {
            return {
                ...state,
                quantity: action.quantity,
            }
        }
        case SET_OPERATIONPOINT_IS_UPDATING: {
            return {
                ...state,
                isUpdating: action.isUpdating,
            }
        }
        default:
            return state
    }
}

export default function roomListReducer(state = { byId: {}, allIds: [] }, action) {
    let allIdIndex
    switch (action.type) {
        case CLONE_OPERATION_POINT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.operationPointId]: reducer(state.byId[action.originOperationPointId], action),
                },
                allIds: [...state.allIds, action.operationPointId],
            }
        case ADD_OPERATION_POINT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.operationPointId]: reducer(state.byId[action.operationPointId], action),
                },
                allIds: [...state.allIds, action.operationPointId],
            }
        case REMOVE_OPERATION_POINT: {
            allIdIndex = state.allIds.indexOf(action.operationPointId)
            return {
                ...state,
                byId: omit(state.byId, [action.operationPointId]),
                allIds: [...state.allIds.slice(0, allIdIndex), ...state.allIds.slice(allIdIndex + 1)],
            }
        }
        case ADD_OPERATION_POINT_CONFIG:
        case DEL_OPERATION_POINT_CONFIG:
        case OPERATION_POINT_REFERENCE_DEVICE:
        case OPERATION_POINT_DEREFERENCE_DEVICE:
        case OPERATION_POINT_REFERENCE_FRAME:
        case OPERATION_POINT_DEREFERENCE_FRAME:
        case REORDER_DEVICES:
        case SET_OPERATION_POINT_QUANTITY:
        case SET_OPERATIONPOINT_IS_UPDATING:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.operationPointId]: reducer(state.byId[action.operationPointId], action),
                },
            }
        default:
            return state
    }
}

// Action Creators
//
export const addOperationPoint = (roomId, operationPointId) => {
    return {
        type: ADD_OPERATION_POINT,
        operationPointId,
        roomId,
    }
}

export const changeQuantity = (combination, quantity, operationPointId = v4()) => {
    return (dispatch, getState) => {
        const project = getEditableProject(getState())
        const activeRoom = getActiveRoom(getState())
        const entities = getAllEntities(getState())
        const deviceList = getDeviceList(getState())
        const frameMapping = getFrameMapping(getState())
        const devicesWithoutFrame = getDevicesWithoutFrame(getState())

        let operationPoint = activeRoom.operationPoints.find(el => {
            return equals(getImageDataFromOperationPoint(project, entities, el.id), combination.imgData)
        })

        if (operationPoint) {
            if (quantity > 0) {
                dispatch(setQuantity(operationPoint.id, quantity))
            } else {
                dispatch(generateDeleteSequence(getState(), operationPoint))
            }
        } else {
            const designRange = getParentDesignRange(getState(), activeRoom.id)
            let sequence = []
            sequence.push(createOperationPoint(
                activeRoom.id,
                operationPointId,
                designRange?.serie,
                designRange?.frameColor,
                designRange?.cplateColor
            ))
            let operationPoint = combination.entities[combination.rootEntityId]
            operationPoint.devices.forEach(deviceId => {
                let newDeviceId = v4()
                let device = combination.entities[deviceId]
                const isSocket = getIsSocket(getState(), device.availableDeviceId)
                const onPiecelist = project.data.projectType === 1 || isSocket
                sequence.push(
                    addDeviceToOperationPoint(
                        device.availableDeviceId,
                        operationPointId,
                        newDeviceId,
                        'device',
                        onPiecelist
                    )
                )
                if (device.devices) {
                    device.devices.forEach(cplateId => {
                        let newCPlateId = v4()
                        let cplate = combination.entities[cplateId]
                        sequence.push(
                            addDeviceToOperationPoint(
                                cplate.availableDeviceId,
                                newDeviceId,
                                newCPlateId,
                                cplate.subtype,
                                true,
                                cplate.position
                            )
                        )
                    })
                }
            })

            sequence.push(setConfigValue(operationPointId, { type: 'orientation', value: combination.orientation }))
            const frameSize = operationPoint.devices.map(id => combination.entities[id]).filter(el => !devicesWithoutFrame.find(el2 => el2 === el.availableDeviceId)).length
            
            let frameData = getFrameDataFromOperationPoint(designRange, frameSize)
            const frame =  calculateFrame(deviceList, frameData.switchRangeId, frameData.frameTypeId, frameData.switchFrameColorId, combination.orientation, frameMapping)
            if (frame) {
                dispatch(addFrameToOpertaionPoint(frame.supplierPid, operationPointId))
            }
            dispatch(batchActions(sequence))
        }
    }
}

export const createOperationPointWithDevice = (combination, operationPointId = v4(), orientation) => {
    return (dispatch, getState) => {
        const project = getEditableProject(getState())
        const activeRoom = getActiveRoom(getState())
        const deviceList = getDeviceList(getState())
        const devicesWithoutFrame = getDevicesWithoutFrame(getState())
        const frameMapping = getFrameMapping(getState())
        const designRange = getParentDesignRange(getState(), activeRoom.id)
        let sequence = []
        sequence.push(createOperationPoint(
            activeRoom.id,
            operationPointId,
            designRange?.serie,
            designRange?.frameColor,
            designRange?.cplateColor
        ))
        let operationPoint = combination.entities[combination.rootEntityId]
        operationPoint.devices.forEach(deviceId => {
            let newDeviceId = v4()
            let device = combination.entities[deviceId]
            const isSocket = getIsSocket(getState(), device.availableDeviceId)
            const onPiecelist = project.data.projectType === 1 || isSocket
            sequence.push(
                addDeviceToOperationPoint(
                    device.availableDeviceId,
                    operationPointId,
                    newDeviceId,
                    'device',
                    onPiecelist
                )
            )
            if (device.devices) {
                device.devices.forEach(cplateId => {
                    let newCPlateId = v4()
                    let cplate = combination.entities[cplateId]
                    sequence.push(
                        addDeviceToOperationPoint(cplate.availableDeviceId, newDeviceId, newCPlateId, cplate.subtype, true, cplate.position)
                    )
                })
            }
        })
        sequence.push(setConfigValue(operationPointId, { type: 'orientation', value: orientation || combination.orientation }))
        const frameSize = operationPoint.devices.map(id => combination.entities[id]).filter(el => !devicesWithoutFrame.find(el2 => el2 === el.availableDeviceId)).length
        
        let frameData = getFrameDataFromOperationPoint(designRange, frameSize)
        const frame =  calculateFrame(deviceList, frameData.switchRangeId, frameData.frameTypeId, frameData.switchFrameColorId, orientation || combination.orientation, frameMapping)
        if (frame) {
            dispatch(addFrameToOpertaionPoint(frame.supplierPid, operationPointId))
        }
        dispatch(batchActions(sequence))
    }
}

export const setQuantity = (operationPointId, quantity) => {
    return {
        type: SET_OPERATION_POINT_QUANTITY,
        operationPointId,
        quantity,
    }
}

export const createOperationPoint = (
    roomId,
    operationPointId = v4(),
    serie,
    frameColor,
    cPlateColor,
) => {
    const sequence = [
        addOperationPoint(roomId, operationPointId),
        buildOperationPointRoomReference(roomId, operationPointId),
    ]

    if (serie) {
        sequence.push(setConfigValue(operationPointId, {type: 'serie', value: serie}))
    }

    if (frameColor) {
        sequence.push(setConfigValue(operationPointId, {type: 'frameColor', value: frameColor}))
    }

    if (cPlateColor) {
        sequence.push(setConfigValue(operationPointId, {type: 'cplateColor', value: cPlateColor}))
    }

    return batchActions(sequence)
}

export const deleteOperationPoint = operationPointId => {
    return {
        type: REMOVE_OPERATION_POINT,
        operationPointId,
    }
}

export const removeOperationPoint = operationPoint => {
    return (dispatch, getState) => {
        if (!operationPoint.parent.hasOwnProperty('id')) {
            operationPoint = getEntityResolved(getState(), operationPoint.id)
        }
        return dispatch(generateDeleteSequence(getState(), operationPoint, 0))
    }
}

export const referenceDeviceWithOperationPoint = (operationPointId, deviceId, index = -1) => {
    return {
        type: OPERATION_POINT_REFERENCE_DEVICE,
        operationPointId,
        deviceId,
        index
    }
}

export const dereferenceDeviceWithOperationPoint = (operationPointId, deviceId) => {
    return {
        type: OPERATION_POINT_DEREFERENCE_DEVICE,
        operationPointId,
        deviceId,
    }
}

export const referenceFrameWithOperationPoint = (operationPointId, deviceId) => {
    return {
        type: OPERATION_POINT_REFERENCE_FRAME,
        operationPointId,
        deviceId,
    }
}

export const dereferenceFrameWithOperationPoint = (operationPointId, deviceId) => {
    return {
        type: OPERATION_POINT_DEREFERENCE_FRAME,
        operationPointId,
        deviceId,
    }
}

export const buildDeviceOperationPointReference = (operationPointId, deviceId) => {
    return referenceDeviceWithOperationPoint(operationPointId, deviceId)
}

export const removeDeviceOperationPointReference = (operationPointId, deviceId) => {
    return dereferenceDeviceWithOperationPoint(operationPointId, deviceId)
}

export const copyOperationPoint = (roomId, originOperationPointId, operationPointId = v4()) => {
    return {
        type: CLONE_OPERATION_POINT,
        roomId,
        originOperationPointId,
        operationPointId,
    }
}

export const cloneOperationPoint = (roomId, originOperationPointId, operationPointId = v4()) => {
    return batchActions([
        copyOperationPoint(roomId, originOperationPointId, operationPointId),
        buildOperationPointRoomReference(roomId, operationPointId),
    ])
}

export const setOrderDevices = (operationPointId, devices) => {
    return {
        type: REORDER_DEVICES,
        operationPointId,
        devices,
    }
}

export const setOperationPointIsUpdating = (operationPointId, isUpdating) => {
    return {
        type: SET_OPERATIONPOINT_IS_UPDATING,
        operationPointId,
        isUpdating,
    }
}

export const getImageDataFromOperationPoint = (project, entities, operationPointId, combOrientation) => {
    const operationPoint = entities[operationPointId]
    const orientation = operationPoint.config?.find(el => el.type === 'orientation')?.value || combOrientation || 'V'

    const data = {
        switchRangeId: project.data.serie,
        switchColorId: project.data.cplateColor,
        switchFrameColorId: project.data.frameColor,
        orientation: orientation,
        groups: [
            {
                frameTypeId: operationPoint.devices.length,
                devices: operationPoint.devices.map(deviceId => {
                    let products = []
                    const device = entities[deviceId]
                    if (device) {
                        products.push({
                            orderNumber: device.availableDeviceId,
                            quantity: 1,
                        })
                        if (device.devices) {
                            device.devices.forEach(cplateId => {
                                const cplate = entities[cplateId]
                                if (cplate) {
                                    products.push({
                                        orderNumber: cplate.availableDeviceId,
                                        quantity: 1,
                                    })
                                }
                            })
                        }
                    }

                    return {
                        frameHeight: 1,
                        products: products,
                    }
                }),
            },
        ],
    }

    if (orientation === 'H') {
        data.height = 80
    } else {
        data.width = 150
    }
    return data
}

export const getFrameDataFromOperationPoint = (designRange, deviceLength) => {
    return {
        switchRangeId: designRange?.serie,
        switchFrameColorId: designRange?.frameColor,
        frameTypeId: deviceLength,
    }
}

export const getOperationPointFromCombination = (state, combination) => {
    return createDeepEqualSelector(
        [getEditableProject, getAllEntities, getActiveRoom],
        (project, entities, activeRoom) => {
            let operationPoint = activeRoom?.operationPoints.find(el => {
                return equals(getImageDataFromOperationPoint(project, entities, el.id), combination?.imgData)
            })
            return operationPoint
        }
    )(state)
}

export const getOperationPointOrientation = (state, operationPointId, combination) => {
    return createDeepEqualSelector([getAllEntities], entities => {
        let operationPoint = entities[operationPointId]
        let orientation = null
        if (combination) {
            operationPoint = getOperationPointFromCombination(state, combination)
            orientation = combination.orientation
        }
        if (operationPoint) {
            const orientation = operationPoint.config?.find(conf => conf.type === 'orientation')
            return orientation?.value
        }
        return orientation
    })(state)
}

export const getOrientation = operationPoint => {
    return operationPoint.config.find(el => el.type === 'orientation')?.value
}
