import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    isSet: false,
    hookurl: null,
    version: null,
    country: null,
    language: null,
    supplierId: 4011395000007,
}

const elbridgeSlice = createSlice({
    name: 'elbridge',
    initialState,
    reducers: {
        setElbridge(state, action) {
            state.isSet = action.payload.isSet
            state.hookurl = action.payload.hookurl
            state.version = action.payload.version
            state.country = action.payload.country
            state.language = action.payload.language
        },
        disableEldbridge(state, action) {
            state.isSet = initialState.isSet
            state.hookurl = initialState.hookurl
            state.version = initialState.version
            state.country = initialState.country
            state.language = initialState.language
        },
    },
})

export const {
    setElbridge,
    disableEldbridge
} = elbridgeSlice.actions
export default elbridgeSlice.reducer

export const getElbridge = state => state.elbridge
