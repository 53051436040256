import { QueryClient } from 'react-query'
import { persistQueryClient, createLocalStoragePersistor } from './persistQueryClient'

export const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      },
    },
})

const localStoragePersistor = createLocalStoragePersistor({
  localStorageKey: `RAUMPLANER_QUERY_OFFLINE_CACHE_${process.env.NODE_ENV.toUpperCase()}`
})

persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
})
